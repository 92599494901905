import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";

// @ts-ignore
import jwt_decode from "jwt-decode";

import { ApiService } from "../shared/services/api.base.service";

import { User } from "./user";
import { environment } from "../../environments/environment";

/* ----------- * - * ----------- */

export let authUserTempKey = "";

const STORAGE_KEYS = {
  currentUser: "currentUser",
  REMEMBER_ME: "REMEMBER_ME",
};

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  constructor(private api: ApiService, private router: Router) {
    const localStorageUser = AuthenticationService.getUserFromLocalStorage();
    this.currentUserSubject = new BehaviorSubject<User>(localStorageUser);
    this.listenerSessionVersion = new BehaviorSubject<number>(
      this.session_version
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.setCurrentUserToLocalStorage(localStorageUser, true);
    this.checkIsTokenExpired();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get session_version(): number {
    return this._session_version;
  }
  private sessionsBaseUrl = "sessions";
  private forgotPasswordURL = "forgotPassword";
  private resetPasswordURL = "resetPassword";
  private updateProfileURL = "users/updateProfile";
  private intervalTokenExpiredCheck: any;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  // tslint:disable-next-line:variable-name
  private _session_version: number = new Date().getTime();
  public listenerSessionVersion: BehaviorSubject<number>;

  // tslint:disable-next-line:typedef
  private static getUserFromLocalStorage() {
    try {
      return JSON.parse(
        localStorage.getItem(STORAGE_KEYS.currentUser) as string
      );
    } catch (error) {
      return null;
    }
  }

  // tslint:disable-next-line:typedef
  searchMac(email: string) {
    return this.api
      .httpPOST<any>(`dashboard/getDeviceByMac`, { mac: email })
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          if (user && user.success == true) {
            const returnUser: User = {
              // @ts-ignore
              id: user.data[0].id,
              mac: user.data[0].mac,
              status: user.data[0].status,
            };
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            this.setCurrentUserToLocalStorage(returnUser);
            localStorage.removeItem("currentDevice");
            localStorage.setItem("currentDevice", JSON.stringify(returnUser));
            localStorage.setItem("currentMode", user.data[0].mode);
          }
          return user;
        })
      );
  }

  // tslint:disable-next-line:typedef
  sendNotification(token: string) {
    return this.api
      .httpPOST<any>(`program/sendNotification`, {
        device: token,
        message: "deviceStatusRefresh",
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  // tslint:disable-next-line:typedef
  getDeviceInfoByDeviceId(id: string) {
    return this.api
      .httpPOST<any>(`dashboard/getDeviceInfoByDeviceId`, { deviceId: id })
      .pipe(
        map((user) => {
          const returnUser: User = {
            // @ts-ignore
            id: user.data[0].id,
            mac: user.data[0].mac,
            status: user.data[0].status,
          };
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.setCurrentUserToLocalStorage(returnUser);
          localStorage.removeItem("currentDevice");
          localStorage.setItem("currentDevice", JSON.stringify(returnUser));
          return user;
        })
      );
  }

  // tslint:disable-next-line:typedef
  setCurrentUserToLocalStorage(user: User, isConstructer: boolean = false) {
    // this.buildIntervalTokenCheck();
    if (!isConstructer) {
      localStorage.setItem(STORAGE_KEYS.currentUser, JSON.stringify(user));
      this.currentUserSubject.next(user);
    }
  }

  // tslint:disable-next-line:typedef
  logout() {
    // remove user from local storage to log user out
    localStorage.clear();
  }

  // tslint:disable-next-line:typedef
  changeSessionVersion() {
    this._session_version = new Date().getTime();
    this.listenerSessionVersion.next(this.session_version);
  }

  // tslint:disable-next-line:typedef
  updatedAuthUserProfile(firstname: string, lastname: string) {
    try {
      const latestUser = {
        ...this.currentUserValue,
        firstname,
        lastname,
      };

      this.setCurrentUserToLocalStorage(latestUser);
    } catch (error) {}
  }

  buildIntervalTokenCheck = () => {
    this.breakIntervalTokenCheck();
    setInterval(() => this.checkIsTokenExpired(), 10000);
  };
  breakIntervalTokenCheck = () => {
    if (this.intervalTokenExpiredCheck) {
      clearInterval(this.intervalTokenExpiredCheck);
    }
  };
  checkIsTokenExpired = () => {
    try {
      const localStorageUser = AuthenticationService.getUserFromLocalStorage();
      if (!localStorageUser) {
        return;
      }

      if (new Date().getTime() > localStorageUser.tokenValidUntil) {
        this.logout();
      }
    } catch (error) {
      this.logout();
    }
  };

  // tslint:disable-next-line:typedef
  removeLoggedInUserName() {
    localStorage.removeItem(STORAGE_KEYS.REMEMBER_ME);
  }

  // tslint:disable-next-line:typedef
  forgotPassword(requestBody: any) {
    return this.api.httpPOST<any>(this.forgotPasswordURL, requestBody);
  }

  // tslint:disable-next-line:typedef
  resetPassword(requestBody: any) {
    return this.api.httpPOST<any>(this.resetPasswordURL, requestBody);
  }

  // tslint:disable-next-line:typedef
  updateProfile(value: any) {
    return this.api.httpPUT<any>(this.updateProfileURL, value);
  }
}
