<!--
<div class="bg-primary" id="layoutAuthentication">
    <div id="layoutAuthentication_content">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-5">
                    <div class="card shadow-lg border-0 rounded-lg mt-5">
                        <div class="card-header">
                            <h3 class="text-center font-weight-light my-4">
                                <center><img src="./assets/img/b-robotics_logo_white.png" style="height: 50px; width: 50px" /></center>
                            </h3>
                        </div>
                        <div class="card-body">
                            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                <div class="form-group">
                                    <label class="small mb-1" for="inputEmailAddress">Email</label>
                                    <input class="form-control py-4" id="inputEmailAddress" type="email" formControlName="email" placeholder="Enter email address" />
                                    <div *ngIf="f.email.errors?.pattern" class="form-text text-danger">Email must be a valid email address</div>
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputPassword">Password</label>
                                    <input class="form-control py-4" id="inputPassword" type="password" formControlName="password" placeholder="Enter password" />
                                </div>
                                &lt;!&ndash;<div class="form-group">
                                    <div class="custom-control custom-checkbox"><input class="custom-control-input" id="rememberPasswordCheck" type="checkbox" /><label class="custom-control-label" for="rememberPasswordCheck">Remember password</label></div>
                                </div>&ndash;&gt;
                                <div class="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                                    <a class="small" [routerLink]="['/forgot-password']">Forgot Password?</a>
                                    <button type="submit" class="btn float-right btn-primary" [disabled]="!loginForm.valid">Login</button>
                                </div>
                            </form>
                        </div>
                        <div class="card-footer text-center">
                            <div class="small"><a [routerLink]="['/sign-up']">Need an account? Sign up!</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="layoutAuthentication_footer">
        <footer class="py-4 bg-light mt-auto">
            <div class="container-fluid">
                <div class="d-flex align-items-center justify-content-between small">
                    <div class="text-muted">Copyright &copy; Your Website 2020</div>
                    <div><a href="#">Privacy Policy &middot;</a><a href="#">Terms &amp; Conditions</a></div>
                </div>
            </div>
        </footer>
    </div>
</div>-->
<ngx-loading
  [show]="loading"
  [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop: true }"
  [template]="customLoadingTemplate"
></ngx-loading>
<ng-template #customLoadingTemplate>
  <div class="custom-class">
    <h3>{{ loaderMessage }}</h3>
  </div>
</ng-template>

<div class="h-100 bg-plum-plate bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-8">
      <!--<div class="app-logo-inverse mx-auto mb-3"></div>-->
      <div class="modal-dialog w-100 mx-auto">
        <div class="modal-content">
          <div class="modal-body">
            <div class="h5 modal-title text-center">
              <h4 class="mt-2">
                <div>SPRINKLER</div>
              </h4>
            </div>
            <div
              id="exampleInputGroup1"
              role="group"
              aria-describedby="exampleInputGroup1__BV_description_"
              class="mb-3"
            >
              <!--<div>
                                <input id="exampleInput1" type="email" placeholder="Enter email..." required="required"
                                       aria-required="true" class="form-control" aria-describedby="exampleInputGroup1__BV_description_">
                                <small tabindex="-1" id="exampleInputGroup1__BV_description_" class="form-text text-muted">MAC address Like: aa:dc:12:89:ca:de.
                                </small>
                            </div>-->
              <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                  <!--<label class="small mb-1" for="inputEmailAddress">MAC ADDRESS</label>-->
                  <input
                    class="form-control py-4"
                    id="inputEmailAddress"
                    type="email"
                    formControlName="email"
                    placeholder="EX: AB:6F:E9:D7:96:DC"
                  />
                  <small
                    tabindex="-1"
                    id="exampleInputGroup1__BV_description_"
                    class="form-text text-muted"
                    >MAC address Like: aa:dc:12:89:ca:de.
                  </small>
                  <div
                    *ngIf="f.email.errors?.pattern"
                    class="form-text text-danger"
                  >
                    MAC address must be a valid.
                  </div>
                </div>
                <!--                                 <div class="form-group">
                                    <label class="small mb-1" for="inputPassword">Password</label>
                                    <input class="form-control py-4" id="inputPassword" type="password" formControlName="password" placeholder="Enter password" />
                                </div> -->
                <div class="modal-footer clearfix">
                  <div class="float-center">
                    <button
                      type="submit"
                      class="btn btn-primary btn-lg"
                      [disabled]="!loginForm.valid"
                    >
                      SEARCH
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!--<div class="modal-footer clearfix">
                        <div class="float-center">
                            <button type="button" class="btn btn-primary btn-lg" [disabled]="!loginForm.valid" >SEARCH</button>
                        </div>
                    </div>-->
        </div>
      </div>
      <div class="text-center text-white opacity-8 mt-3">
        Copyright © Sprinkler Systems 2023
      </div>
    </div>
  </div>
</div>
