<ngx-loading
  [show]="loading"
  [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop: true }"
  [template]="customLoadingTemplate"
></ngx-loading>
<ng-template #customLoadingTemplate>
  <div class="custom-class">
    <h3>{{ loaderMessage }}</h3>
  </div>
</ng-template>

<app-left-menu></app-left-menu>

<div class="tableFixHead app-header header-shadow header">
  <div class="logo-src"></div>
  <div class="app-header__content">
    <div class="app-header-right" style="margin-right: 180px">
      Device Status:&nbsp;
      <p-toggleButton
        [(ngModel)]="status"
        onLabel="ON"
        offLabel="OFF"
        disabled
      ></p-toggleButton>
    </div>
  </div>

  <div class="header-btn-lg1 app-header__content">
    <div style="margin: 0px 10px 6px 0px">Normal</div>
    <div class="row1">
      <div>
        <p-inputSwitch
          (onChange)="modeChange($event)"
          [(ngModel)]="mode"
          [disabled]="status == true ? false : true"
        ></p-inputSwitch>
      </div>
    </div>
    <div style="margin: 0px 0px 6px 10px">Maintenance</div>

    <div class="app-header-right">
      <div class="d-flex">
        <div class="header-btn-lg pr-0">
          <div class="widget-content p-0">
            <div class="widget-content-wrapper">
              <div class="widget-content-left ml-3 header-user-info">
                <div class="widget-subheading">
                  <b>{{ this.mac_add }}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
