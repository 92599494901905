import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {map, catchError} from 'rxjs/operators';

import {environment} from '../../../environments/environment';
import {Observable, throwError} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ApiService {
    private baseURL = environment.baseUrl;

    constructor(private http: HttpClient) {
    }

    httpGET<T>(URL: any, responseDataAccessor?: string): Observable<T> {
        return this.http.get<T>(`${this.baseURL}${URL}`)
            .pipe(
                map(data => this.handlePipe(data, responseDataAccessor)),
                catchError(this.handleError)
            );
    }

    httpPOST<T>(URL: any, requestBody: any, responseDataAccessor?: string): Observable<T> {
        return this.http.post<T>(`${this.baseURL}${URL}`, requestBody)
            .pipe(
                map(data => this.handlePipe(data, responseDataAccessor)),
                catchError(this.handleError)
            );
    }

    httpPUT<T>(URL: any, requestBody: any, responseDataAccessor?: string): Observable<T> {
        return this.http.put<T>(`${this.baseURL}${URL}`, requestBody ? requestBody : null)
            .pipe(
                map(data => this.handlePipe(data, responseDataAccessor)),
                catchError(this.handleError)
            );
    }

    httpDELETE<T>(URL: any, requestBody?: any): Observable<T> {
        return this.http.delete<T>(`${this.baseURL}${URL}`)
            .pipe(
                catchError(this.handleError)
            );
    }

    // tslint:disable-next-line:typedef
    private handlePipe(data: any, responseDataAccessor?: string) {
        return responseDataAccessor ? data[responseDataAccessor] : data;
    }

    // tslint:disable-next-line:typedef
    private handleError(err: string) {
        console.error(err);
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage: string;
        // @ts-ignore
        if (err instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = err;
        }
        return throwError(errorMessage);
    }
}
