import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserModule} from './user/user.module';
import {SharedModule} from './shared/shared.module';
import {DashboardModule} from './dashboard/dashboard.module';
import { TrackersModule } from './trackers/trackers.module';


const routes: Routes = [
    {path: '', redirectTo: 'sign-in', pathMatch: 'full'},
    {path: 'user', loadChildren: './user/user.module#UseModule'},
    {path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule'},
    { path: 'trackers', loadChildren: './trackers/trackers.module#TrackersModule' },
];

@NgModule({
    imports: [UserModule,
        SharedModule,
        DashboardModule,
        TrackersModule,
        RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
