import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { finalize, first } from "rxjs/operators";
import { AuthService } from "src/app/shared/services/auth.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  forgotpasswordForm!: FormGroup;
  loading = false;
  submitted = false;
  isSuccessful = false;
  isFailed = false;
  errorMessage = "";
  message = "";
  messages = "";
  account = this.authService.currentUserValue.jwtToken;

  // message = 'Please check your email for password reset instructions';

  get f() {
    return this.forgotpasswordForm.controls;
  }

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {}

  mailpattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";

  ngOnInit(): void {
    this.forgotpasswordForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.pattern(this.mailpattern)]],
    });
  }

  // tslint:disable-next-line:typedef
  onSubmit() {
    this.submitted = true;
    if (this.forgotpasswordForm.invalid) {
      return;
    }

    /*        const alertService = this.alertService;
        setTimeout(() => {
           const resetUrl = `${location.origin}/reset-password?token=${this.account}`;
           alertService.info(`
               <h4>Reset Password Email</h4>
               <p>Please click the below link to reset your password, the link will be valid for 1 day:</p>
               <p><a href="${resetUrl}">${resetUrl}</a></p>
               // tslint:disable-next-line:max-line-length
               // tslint:disable-next-line:max-line-length
               <div><strong>NOTE:</strong> The fake backend displayed this "email" so you can test without an api. A real backend would send a real email.</div>
           `, { autoClose: false });
         }, 1000);*/
    this.loading = true;
    this.authService
      .forgotPassword(this.f.email.value)
      .pipe(first())
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: () => {
          // this.alertService.success('Please check your email for password reset instructions'),
          Swal.fire(
            "Success",
            "Please check your email for password reset instructions",
            "success"
          );
          this.isSuccessful = true;
          this.isFailed = false;
          this.message =
            "Please check your email for password reset instructions";
          this.router.navigateByUrl("/reset-password");
        },
        error: (error) => {
          this.errorMessage = error.error.message;
          Swal.fire("Error: ", this.errorMessage, "error");
          this.isFailed = true;
          this.messages = "error";
        },
      });
  }
}
