import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { User } from "../models/user";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser") || "{}")
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  private refreshTokenTimeout: any;

  // tslint:disable-next-line:typedef
  login(email: string, password: string) {
    return this.http
      .post<any>(`${environment.baseUrl}users/authenticate`, {
        EmailId: email,
        Password: password,
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes

          localStorage.setItem("currentUser", JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  register(user: any): Observable<any> {
    return this.http.post(
      `${environment.baseUrl}users/register`,
      {
        FirstName: user.firstName,
        LastName: user.lastName,
        EmailId: user.email,
        Password: user.password,
        // confirmPassword: user.confirmPassword,
      },
      httpOptions
    );
  }

  // tslint:disable-next-line:typedef
  create(params: any) {
    return this.http.post(environment.baseUrl, +"accounts", params);
  }

  // tslint:disable-next-line:typedef
  update(id: string, params: any) {
    return this.http.put(`${environment.baseUrl}accounts/${id}`, params).pipe(
      map((account: any) => {
        // update the current account if it was updated
        if (account.id === this.currentUserValue.id) {
          // publish updated account to subscribers
          account = { ...this.currentUserValue, ...account };
          this.currentUserSubject.next(account);
        }
        return account;
      })
    );
  }

  // tslint:disable-next-line:typedef
  verifyEmail(token: string) {
    // @ts-ignore
    return this.http.get(
      `${environment.baseUrl}users/activate?EmailId=` + token
    );
  }

  // tslint:disable-next-line:typedef
  forgotPassword(email: string) {
    return this.http.post(`${environment.baseUrl}accounts/forgot-password`, {
      email,
    });
  }

  // tslint:disable-next-line:typedef
  resetPassword(token: string, password: string, confirmPassword: string) {
    return this.http.post(`${environment.baseUrl}accounts/reset-password`, {
      token,
      password,
      confirmPassword,
    });
  }

  // tslint:disable-next-line:typedef
  validateResetToken(token: string) {
    return this.http.post(
      `${environment.baseUrl}accounts/validate-reset-token`,
      { token }
    );
  }
  // tslint:disable-next-line:typedef
  logout() {
    // this.http.post<any>(`${environment.baseUrl}accounts/revoke-token`, {}, { withCredentials: true }).subscribe();
    // this.stopRefreshTokenTimer();
    //  this.currentUserSubject.next(null);
    this.router.navigate(["/sign-in"]);
  }
}
