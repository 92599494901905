import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { first } from "rxjs/operators";
import { AuthService } from "src/app/shared/services/auth.service";
import Swal from "sweetalert2";
import { AuthenticationService } from "../../authorization/authentication.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  submitted = false;
  isLoginFailed = false;
  errorMessage = "";
  fbtoken: any;

  public loading = false;
  public loaderMessage = "";
  // tslint:disable-next-line:typedef
  get f() {
    return this.loginForm.controls;
  }
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService
  ) {}

  macpattern = "^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$";

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.pattern(this.macpattern)]],
    });
  }

  // tslint:disable-next-line:typedef
  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.loaderMessage = "Logging in";
    const re = /:/gi;
    const macAddress = this.f.email.value.replace(re, "");
    this.authenticationService
      .searchMac(macAddress)
      .pipe(first())
      .subscribe(
        (data) => {
          this.isLoginFailed = false;
          if (data.success == true) {
            this.fbtoken = localStorage.getItem("fbtoken");

            // @ts-ignore
            this.authenticationService
              .sendNotification(this.fbtoken)
              .pipe(first())
              .subscribe(
                (data) => {},
                (err) => {
                  this.errorMessage = err.error.data;

                  Swal.fire("Failed: ", this.errorMessage, "error");
                }
              );
            if (data.data[0].mode === "normal") {
              this.router.navigateByUrl("/normal");
            } else {
              this.router.navigateByUrl("/dashboard");
            }
          } else {
            this.loading = false;
            Swal.fire("Failed: ", "Device not found on this mac.", "error");
            this.router.navigateByUrl("/sign-in");
          }
        },
        (err) => {
          // display api error message.
          this.errorMessage = err.error.data;
          this.loading = false;
          Swal.fire("Failed: ", this.errorMessage, "error");
          this.isLoginFailed = true;
        }
      );
  }
}
