import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { WifiConfigurationComponent } from './wifi-configuration/wifi-configuration.component';
import {wiFiConfigResolver} from '../shared/services/tracker-resolver.service';
import {DropdownModule} from 'primeng/dropdown';
import {AuthGuard} from '../authorization/auth.guard';


const routes: Routes = [

    // tslint:disable-next-line:max-line-length
    {
        path: 'wifiConfiguration',
        component: WifiConfigurationComponent,
        resolve: { wifiConfigData: wiFiConfigResolver },
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        WifiConfigurationComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
        SharedModule,
        DropdownModule
    ],
    exports: [RouterModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})

export class TrackersModule {
}
