import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TrackerService } from "src/app/shared/services/tracker.service";
import { AuthService } from "../../shared/services/auth.service";
import Swal from "sweetalert2";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "wifi-configuration",
  templateUrl: "./wifi-configuration.component.html",
  styleUrls: ["./wifi-configuration.component.css"],
})
export class WifiConfigurationComponent implements OnInit {
  // id = this.authService.currentUserValue.id;
  wifiData: any;
  // tslint:disable-next-line:typedef
  get f() {
    return this.wifiConfigurationForm.controls;
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    /*private serviceAuthentication: AuthenticationService,*/
    private trackerService: TrackerService
  ) {}

  wifiConfigurationForm!: FormGroup;
  submitted = false;
  loading = false;
  id!: string;
  isAddMode!: boolean;
  deviceStatus: any;
  // tslint:disable-next-line:typedef
  securityType: any;
  // tslint:disable-next-line:typedef
  ngOnInit() {
    this.id = this.route.snapshot.params.id;
    this.wifiData = this.route.snapshot.data.wifiConfigData.data[0];
    this.deviceStatus = this.wifiData.status;
    this.wifiConfigurationForm = this.formBuilder.group({
      SSID: [this.wifiData.ssid, Validators.required],
      password: [this.wifiData.password, [Validators.required]],
      securityType: [this.wifiData.securityType, [Validators.required]],
    });
    this.securityType = this.wifiData.securityType;
  }

  // tslint:disable-next-line:typedef
  onSubmit() {
    this.submitted = true;
    if (this.wifiConfigurationForm.invalid) {
      return;
    }

    const updateDvc = {
      deviceId: this.wifiData.id,
      wifiId: this.wifiData.wifiId,
      ssid: this.f.SSID.value,
      securityType: this.f.securityType.value,
      password: this.f.password.value,
    };
    this.trackerService.updateWifiDetails(updateDvc).subscribe({
      next: () => {
        Swal.fire(
          "Good job!",
          "WiFi configuration updated successfully.",
          "success"
        );
        this.router.navigate(["/wifiConfiguration"], {
          relativeTo: this.route,
        });
      },
      error: (error) => {
        Swal.fire("Failed: ", error, "error");
        this.loading = false;
      },
    });
  }

  onReset() {
    this.submitted = false;
    this.wifiConfigurationForm.reset();
  }
}
