<app-header></app-header>

<div class="main-header">
  <div class="card-body">
    <div id="layoutSidenav_content">
      <div *ngIf="deviceStatus === 0">
        <div
          class="ng-tns-c3-0 ui-toast ui-widget ui-toast-top-right"
          style="margin: 50px -15px 0px 0px; z-index: 1001"
        >
          <p-toastitem
            class="ng-tns-c5-1 ng-trigger ng-trigger-toastAnimation ng-tns-c3-0 ng-star-inserted"
            ng-reflect-message="[object Object]"
            ng-reflect-index="0"
            ng-reflect-show-transition-options="300ms ease-out"
            ng-reflect-hide-transition-options="250ms ease-in"
          >
            <div
              class="ui-toast-message ui-shadow ui-toast-message-info ng-trigger ng-trigger-messageState"
              ng-reflect-klass="ui-toast-message ui-shadow"
              ng-reflect-ng-class="[object Object]"
              style="transform: translateY(0px); opacity: 1"
            >
              <div class="ui-toast-message-content">
                <span
                  class="ui-toast-icon pi ng-tns-c5-1 pi-exclamation-triangle ng-star-inserted"
                  style=""
                ></span>
                <div
                  class="ui-toast-message-text-content ng-tns-c5-1 ng-star-inserted"
                  style=""
                >
                  <div class="ui-toast-summary">Alert</div>
                  <div class="ui-toast-detail">
                    Sprinkler is currently OFF. You can not operate it. Please
                    turn it ON to operate it.
                  </div>
                </div>
              </div>
            </div>
          </p-toastitem>
        </div>
      </div>
      <main>
        <div class="container-fluid px-4">
          <h1 class="mt-4">Wifi</h1>
          <ol class="breadcrumb mb-4">
            <li class="breadcrumb-item active">Wifi Configuration</li>
          </ol>
          <div class="mb-3 card">
            <div class="card-header-tab card-header">
              <div class="card-body">
                <div class="sbp-preview">
                  <div class="sbp-preview-content">
                    <form
                      [formGroup]="wifiConfigurationForm"
                      class="ng-untouched ng-pristine ng-valid"
                    >
                      <div class="mb-3">
                        <label>SSID</label>
                        <input
                          type="text"
                          formControlName="SSID"
                          class="form-control"
                          [ngClass]="{
                            'is-invalid': submitted && f.SSID.errors
                          }"
                          placeholder="Enter SSID"
                        />
                        <div
                          *ngIf="submitted && f.SSID.errors"
                          class="form-text text-danger"
                        >
                          <div *ngIf="f.SSID.errors.required">
                            SSID is required
                          </div>
                        </div>
                      </div>

                      <div class="mb-3">
                        <label>Security</label>
                        <select
                          formControlName="securityType"
                          class="form-control"
                          [ngModel]="securityType"
                          [ngClass]="{
                            'is-invalid': submitted && f.securityType.errors
                          }"
                        >
                          <option value="open">open</option>
                          <option value="WPA/WPA2-Personal">
                            WPA/WPA2-Personal
                          </option>
                          <option value="WPA2/WPA3-Personal">
                            WPA2/WPA3-Personal
                          </option>
                          <option value="WPA3-Personal">WPA3-Personal</option>
                        </select>
                        <div
                          *ngIf="submitted && f.securityType.errors"
                          class="form-text text-danger"
                        >
                          <div *ngIf="f.securityType.errors.required">
                            Security type is required
                          </div>
                        </div>
                      </div>

                      <div class="mb-3">
                        <label>Password</label>
                        <input
                          type="password"
                          formControlName="password"
                          class="form-control"
                          [ngClass]="{
                            'is-invalid': submitted && f.password.errors
                          }"
                          placeholder="Enter password"
                        />
                        <div
                          *ngIf="submitted && f.password.errors"
                          class="form-text text-danger"
                        >
                          <div *ngIf="f.password.errors.required">
                            Password is required
                          </div>
                        </div>
                      </div>
                      <div *ngIf="deviceStatus === 1">
                        <div class="text-center">
                          <button
                            class="btn btn-primary mr-1"
                            (click)="onSubmit()"
                          >
                            Save Configuration
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>
