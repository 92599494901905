import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

/*import { AuthService } from '../shared/services/auth.service';*/
import { AuthenticationService } from "./authentication.service";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  // tslint:disable-next-line:typedef
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      if (currentUser.mac != null) {
        // authorised so return true
        return true;
      } else {
        this.authenticationService.logout();
        this.router.navigate(["/sign-in"], {
          queryParams: { returnUrl: state.url },
        });
        return false;
      }
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(["/sign-in"], {
      queryParams: { returnUrl: state.url },
    });
    return false;
  }
}
