import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { VehicleService } from './services/vehicle.service';
import { TrackerService } from './services/tracker.service';
import {CommonModule} from '@angular/common';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ToggleButtonModule} from 'primeng/primeng';
import {FormsModule} from '@angular/forms';
import {NgxLoadingModule} from 'ngx-loading';

const routes: Routes = [
];
@NgModule({
  declarations: [
    HeaderComponent,
    LeftMenuComponent
  ],
    imports: [
        RouterModule,
        CommonModule,
        FontAwesomeModule,
        InputSwitchModule,
        ToggleButtonModule,
        FormsModule,
        NgxLoadingModule,
    ],
  exports: [
    HeaderComponent,
    LeftMenuComponent
  ],
  providers: [VehicleService, TrackerService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})

export class SharedModule { }
