<html>

<body>

<div class="container">
    <div class="d-flex justify-content-center" style="height: 668px; color:white">
        <div class="card">
        </div>
    </div>
</div>
</body>

</html>
