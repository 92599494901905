import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { SharedModule } from '../shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import {VerifyEmailComponent} from './verify-email/verify-email.component';

const routes: Routes = [
  { path: '', redirectTo: 'sign-in', pathMatch: 'full' },
  { path: 'sign-in', component: LoginComponent },
  { path: 'sign-up', component: RegisterComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'edit-user', component: EditUserComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  { path: 'verify-email/:EmailId', component: VerifyEmailComponent }
];

@NgModule({
  imports: [
    BrowserModule,
    FormsModule, ReactiveFormsModule,
    SharedModule,
    NgSelectModule,
    RouterModule.forChild(routes)],
  exports: [RouterModule],
  declarations: [
    ForgotPasswordComponent,
    EditUserComponent,
    ResetPasswordComponent,
    VerifyEmailComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})

export class UserModule { }
