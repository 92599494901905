<ngx-loading
  [show]="loading"
  [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop: true }"
  [template]="customLoadingTemplate"
></ngx-loading>
<ng-template #customLoadingTemplate>
  <div class="custom-class">
    <h3>{{ loaderMessage }}</h3>
  </div>
</ng-template>
<app-header xmlns="http://www.w3.org/1999/html"></app-header>
<div class="main-header">
  <div class="card-body">
    <div id="layoutSidenav_content">
      <div *ngIf="deviceStatus === 0">
        <div
          class="ng-tns-c3-0 ui-toast ui-widget ui-toast-top-right"
          style="margin: 50px -15px 0px 0px; z-index: 1001"
        >
          <p-toastitem
            class="ng-tns-c5-1 ng-trigger ng-trigger-toastAnimation ng-tns-c3-0 ng-star-inserted"
            ng-reflect-message="[object Object]"
            ng-reflect-index="0"
            ng-reflect-show-transition-options="300ms ease-out"
            ng-reflect-hide-transition-options="250ms ease-in"
          >
            <div
              class="ui-toast-message ui-shadow ui-toast-message-info ng-trigger ng-trigger-messageState"
              ng-reflect-klass="ui-toast-message ui-shadow"
              ng-reflect-ng-class="[object Object]"
              style="transform: translateY(0px); opacity: 1"
            >
              <div class="ui-toast-message-content">
                <span
                  class="ui-toast-icon pi ng-tns-c5-1 pi-exclamation-triangle ng-star-inserted"
                ></span>
                <div
                  class="ui-toast-message-text-content ng-tns-c5-1 ng-star-inserted"
                >
                  <div class="ui-toast-summary">Alert</div>
                  <div class="ui-toast-detail">
                    Sprinkler is currently OFF. You can not operate it. Please
                    turn it ON to operate it.
                  </div>
                </div>
              </div>
            </div>
          </p-toastitem>
        </div>
      </div>

      <main>
        <div class="container-fluid px-4">
          <h4 class="mt-4">Program Configuration</h4>
          <ol class="breadcrumb mb-4">
            <li class="breadcrumb-item active">Program</li>
          </ol>
        </div>
        <form
          [formGroup]="editProgram"
          class="ng-untouched ng-pristine ng-valid"
        >
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
              <div class="main-card mb-3 card">
                <div class="card-body">
                  <div class="bg-title">
                    <label class="bg-title">&nbsp;&nbsp;Config:</label>
                  </div>
                  <div>
                    <br />
                    <div class="form-check form-check-inline">
                      <input
                        formControlName="enabled"
                        class="form-check-input"
                        type="checkbox"
                        id="enable"
                        value="{{ this.enabled }}"
                        disabled
                      />
                      <label class="form-check-label" for="enable"
                        >&nbsp;&nbsp;&nbsp; Enable Program</label
                      >
                    </div>
                  </div>
                  <br />
                  <div class="position-relative mb-3">
                    <label for="program-label" class="form-label"
                      >Program Label:</label
                    >
                    <input
                      formControlName="programLabel"
                      name="program-label"
                      id="program-label"
                      placeholder="program name"
                      type="text"
                      class="form-control"
                      disabled
                    />
                  </div>
                  <br />
                  <div class="bg-title">
                    <label class="form-label bg-title"
                      >&nbsp;&nbsp;Schedule:</label
                    >
                  </div>
                  <br />
                  <div class="position-relative mb-3">
                    <label for="startTimeId" class="form-label"
                      >Start time:</label
                    ><br />
                    <p-calendar
                      formControlName="startTime"
                      id="startTimeId"
                      [timeOnly]="true"
                      hourFormat="24"
                      [(ngModel)]="startTime"
                      [showIcon]="true"
                      [disabled]="true"
                    ></p-calendar>
                  </div>
                  <br />
                  <div class="position-relative mb-3">
                    <div class="position-relative">
                      <div>
                        <b>Days:</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <div class="form-check form-check-inline">
                          <input
                            formControlName="days_sun"
                            class="form-check-input"
                            type="checkbox"
                            id="sun"
                            value="option1"
                            disabled
                          />
                          <label class="form-check-label" for="sun">Sun</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            formControlName="days_mon"
                            class="form-check-input"
                            type="checkbox"
                            id="mon"
                            value="option2"
                            disabled
                          />
                          <label class="form-check-label" for="mon">Mon</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            formControlName="days_tue"
                            class="form-check-input"
                            type="checkbox"
                            id="tue"
                            value="option2"
                            disabled
                          />
                          <label class="form-check-label" for="tue">Tue</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            formControlName="days_wed"
                            class="form-check-input"
                            type="checkbox"
                            id="wed"
                            value="option2"
                            disabled
                          />
                          <label class="form-check-label" for="wed">Wed</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            formControlName="days_thu"
                            class="form-check-input"
                            type="checkbox"
                            id="thu"
                            value="option2"
                            disabled
                          />
                          <label class="form-check-label" for="thu">Thu</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            formControlName="days_fri"
                            class="form-check-input"
                            type="checkbox"
                            id="fri"
                            value="option2"
                            disabled
                          />
                          <label class="form-check-label" for="fri">Fri</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            formControlName="days_sat"
                            class="form-check-input"
                            type="checkbox"
                            id="sat"
                            value="option2"
                            disabled
                          />
                          <label class="form-check-label" for="sat">Sat</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div>
                    <div class="form-check form-check-inline">
                      <input
                        formControlName="isRepeat"
                        class="form-check-input"
                        type="checkbox"
                        id="repeat"
                        value="true"
                        disabled
                      />
                      <label class="form-check-label" for="repeat"
                        >&nbsp;&nbsp;&nbsp; Repeat</label
                      >
                    </div>
                  </div>
                  <br />

                  <div class="row">
                    <div class="position-relative mb-3">
                      <div class="bg-title">
                        <label class="form-label bg-title"
                          >&nbsp;&nbsp;Zone:</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4" *ngFor="let zoneValue of addedZones">
                      <div
                        class="card corner mb-3 widget-chart widget-chart2 text-start card-btm-border card-shadow card-border-tab"
                        [style.background-image]="
                          zoneValue.status == '0'
                            ? ''
                            : 'linear-gradient(356deg, rgb(152 200 230) 0%, rgb(92 115 233) 100%)'
                        "
                      >
                        <div class="widget-chat-wrapper-outer">
                          <div class="widget-chart-content pt-3 ps-3 pb-1">
                            <div class="row1">
                              <div class="column-switch">
                                <i
                                  class="fa fa-fw"
                                  aria-hidden="true"
                                  style="font-size: 24px"
                                  title="lightbulb-o"
                                  ></i
                                >
                              </div>
                            </div>
                            <div class="widget-chart-flex">
                              <div class="widget-numbers">
                                <div
                                  class="widget-chart-flex"
                                  style="margin: 18px 0 10px 0px"
                                >
                                  <div class="fsize">
                                    <small class="opacity-5">{{
                                      zoneValue.name
                                    }}</small>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="widget-chart-flex"
                              style="margin: 15px 0 0 0"
                            >
                              <div class="row1">
                                <div class="duration-icon">
                                  <i
                                    class="pi pi-clock"
                                    style="font-size: 30px"
                                  ></i>
                                </div>
                                <div>
                                  <small class="opacity-5"
                                    >{{ zoneValue.time }} min
                                    &nbsp;&nbsp;</small
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row1">
                    <div
                      style="
                        align-items: center;
                        text-align: center;
                        margin: 0 0 0 30px;
                      "
                    >
                      <button
                        class="mt-1 btn btn-primary"
                        style="width: 20%"
                        (click)="onBack()"
                      >
                        Back</button
                      >&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </main>
    </div>
  </div>
</div>
