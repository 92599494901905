import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";

import { TrackerService } from "./tracker.service";
import { AuthService } from "./auth.service";
import { AuthenticationService } from "../../authorization/authentication.service";

@Injectable({
  providedIn: "root",
})
export class TrackersResolver implements Resolve<any> {
  constructor(
    private trackerService: TrackerService,
    private authenticationService: AuthenticationService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    // @ts-ignore
    const email = this.authenticationService.currentUser.source._value.mac;
    // @ts-ignore
    const deviceId = this.authenticationService.currentUser.source._value.id;

    return (
      this.trackerService
        .getProgramListByDeviceId(deviceId)
        // @ts-ignore
        .pipe(
          map((deviceDetails: any) => {
            if (deviceDetails) {
              return deviceDetails;
            }
          }),
          catchError((error) => {
            const message = `Retrieval error: ${error}`;
            console.error(message);
            return Observable.throw(error);
          })
        )
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class TrackerResolver implements Resolve<any> {
  constructor(private trackerService: TrackerService) {}

  // @ts-ignore
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    const id = route.paramMap.get("id");

    const reqBody = id;

    return this.trackerService.getProgram(reqBody).pipe(
      // @ts-ignore
      map((trackers) => {
        if (trackers) {
          return trackers;
        }
      }),
      catchError((error) => {
        const message = `Retrieval error: ${error}`;
        console.error(message);
        return Observable.throw(error);
      })
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class ZoneInfoResolver implements Resolve<any> {
  constructor(
    private trackerService: TrackerService,
    private authenticationService: AuthenticationService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    // @ts-ignore
    const deviceid = this.authenticationService.currentUser.source._value.id;

    return (
      this.trackerService
        .getPeripheralsByDeviceId(deviceid)
        // @ts-ignore
        .pipe(
          map((deviceDetails: any) => {
            if (deviceDetails) {
              return deviceDetails;
            }
          }),
          catchError((error) => {
            const message = `Retrieval error: ${error}`;
            console.error(message);
            return Observable.throw(error);
          })
        )
    );
  }
}

@Injectable({
  providedIn: "root",
})
// tslint:disable-next-line:class-name
export class wiFiConfigResolver implements Resolve<any> {
  constructor(
    private trackerService: TrackerService,
    private authenticationService: AuthenticationService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    // @ts-ignore
    const deviceid = this.authenticationService.currentUser.source._value.id;

    return (
      this.trackerService
        .getDeviceInfoByDeviceId(deviceid)
        // @ts-ignore
        .pipe(
          map((deviceDetails: any) => {
            if (deviceDetails) {
              return deviceDetails;
            }
          }),
          catchError((error) => {
            const message = `Retrieval error: ${error}`;
            console.error(message);
            return Observable.throw(error);
          })
        )
    );
  }
}

/*@Injectable({
    providedIn: 'root'
})
export class MQTTFactoryResolver implements  Resolve<any>{
    constructor(private trackerService: TrackerService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

        const deviceId = route.paramMap.get('id');
        const brocker = route.paramMap.get('broker');
        const port = route.paramMap.get('port');

        return this.trackerService.getMQTTFactoryDeviceData(deviceId, brocker, port)
            // @ts-ignore
            .pipe(map(deviceDetails => {
                    if (deviceDetails) {
                        return deviceDetails;
                    }
                }),
                catchError(error => {
                    const message = `Retrieval error: ${error}`;
                    console.error(message);
                    return Observable.throw(error);
                })
            );
    }
}*/
