import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MustMatch } from '../../shared/custom-form-validators/must-match.validator';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  signUpForm!: FormGroup;
  submitted = false
  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = '';

  get f() { return this.signUpForm.controls; }

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private authService: AuthService) { }

  mailpattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';

  ngOnInit(): void {
    this.signUpForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.mailpattern)]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
      token: ['']
    },
      {
        validator: MustMatch('password', 'confirmPassword')
      });
  }

  // tslint:disable-next-line:typedef
  onSubmit() {
    this.submitted = true;
    if (this.signUpForm.invalid) {
      return;
    }
    this.authService.register(this.signUpForm.value).subscribe(
      data => {
        // this.isSuccessful = true;
        Swal.fire('Success', 'Your registration is successful, Please confirm your email address For login!','success');
        this.router.navigateByUrl('/sign-in');
        this.isSignUpFailed = false;

        // this.router.navigateByUrl('/dashboard');
      },
      err => {
        this.errorMessage = err.error.message;
        Swal.fire('Failed: ', this.errorMessage, 'error');
        this.isSignUpFailed = true;
      }
    );
  }
  reloadPage(): void {
    window.location.reload();
  }
}
