import { Component, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "../authorization/authentication.service";
import { TrackerService } from "../shared/services/tracker.service";
import { NgbNavChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { getMessaging, onMessage } from "firebase/messaging";
import { MessageService } from "primeng/api";
import { first } from "rxjs/operators";
import { environment } from "../../environments/environment";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
  providers: [MessageService],
})
export class DashboardComponent implements OnInit {
  data: any;
  zoneInfoData: any;
  mac: any;
  deviceId: any;
  intervalAPICallRef: any;
  message =
    "You can not turn ON any other program as one program test is already running.";
  tabvalue = "Alert";
  active: number | undefined;
  disabled = true;
  val: any;
  // tslint:disable-next-line:typedef
  zone: any;

  flagButton: boolean = false;
  deviceStatus: any;
  account: any;
  headerData: any;
  modeValue: any;
  statusValue: any;
  status: any;
  errorMessage: any;
  mode: any;
  deviceMode: any;
  public loading = true;
  public loaderMessage = "";
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private trackerService: TrackerService,
    private messageService: MessageService
  ) {}

  // tslint:disable-next-line:typedef
  ngOnInit() {
    this.listen();
    // @ts-ignore
    this.mac = this.authenticationService.currentUser.source._value.mac;

    // @ts-ignore
    this.deviceId = this.authenticationService.currentUser.source._value.id;
    // @ts-ignore
    this.deviceStatus = this.route.snapshot.data.deviceData.data[0].status;

    this.data = this.route.snapshot.data.deviceInfo.data;
    this.deviceMode = this.route.snapshot.data.deviceData.data[0].mode;
    if (this.deviceMode === "maintenance") {
      this.router.navigate(["/dashboard"]);
    }

    this.account = this.authenticationService.currentUserValue;

    const flagButtonArray = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.data.length; i++) {
      flagButtonArray.push(this.data[i].status);
    }
    if (flagButtonArray.includes(1)) {
      this.flagButton = true;
    } else {
      this.flagButton = false;
    }
    if (this.deviceStatus === 0) {
      this.flagButton = true;
      this.tabvalue = "Alert";
      this.message =
        "Sprinkler is currently OFF. You can not operate it. Please turn it ON to operate it.";
    }
    this.zoneInfoData = this.route.snapshot.data.zoneInfo.data;
    this.loading = false;
  }
  // tslint:disable-next-line:typedef
  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload: any) => {
      if (payload?.data.text === "zoneRefresh") {
        this.trackerService.getPeripheralsByDeviceId(this.deviceId).subscribe({
          next: (data: any) => {
            this.zoneInfoData = data.data;
          },
          error: (error) => {
            Swal.fire("Failed:", error, "error").then((r) => r);
          },
        });
      }
      if (payload?.data.text === "programRefresh") {
        this.trackerService.getProgramListByDeviceId(this.deviceId).subscribe({
          next: (data: any) => {
            this.data = data.data;
          },
          error: (error) => {
            Swal.fire("Failed:", error, "error").then((r) => r);
          },
        });
      }
      if (payload?.data.text === "deviceStatusRefresh") {
        location.reload();
      }
    });
  }
  // tslint:disable-next-line:typedef
  onNavChange(changeEvent: NgbNavChangeEvent) {
    if (changeEvent.activeId === 1) {
      this.tabvalue = "Alert";
      this.message =
        "You can not turn ON any other program as one program test is already running.";
    } else if (changeEvent.activeId === 2) {
      this.tabvalue = "Alert";
      this.message =
        "Zone can not be tested separately when any of the program is under testing. To test the zone,please turn of the program or wait till the test of the program will be done.";
    }
    if (this.deviceStatus === 0) {
      this.tabvalue = "Alert";
      this.message =
        "Sprinkler is currently OFF. You can not operate it. Please turn it ON to operate it.";
    }
    if (changeEvent.nextId === 3) {
      changeEvent.preventDefault();
    }
  }
  // tslint:disable-next-line:typedef
  handleZoneChange(zoneValue: any) {
    let statusFlag: number;

    if (zoneValue.status === false) {
      statusFlag = 0;
    } else {
      statusFlag = 1;
    }
    this.loaderMessage = "Changing zone status.";

    this.loading = true;
    this.trackerService
      .setZoneStatus(zoneValue.id, zoneValue.deviceId, statusFlag)
      .subscribe({
        next: (data) => {
          // @ts-ignore
          // Swal.fire('Success', data.data, 'success').then((r) => r);
          this.loading = false;
          // this.router.navigate(['/deviceDetails/' + this.mqttData.Device_ID], {relativeTo: this.route});
        },
        error: (error) => {
          // Swal.fire('Failed:', error, 'error').then((r) => r);
          this.loading = false;
        },
      });
  }

  // tslint:disable-next-line:typedef
  handleProgramChange(dataValue: any) {
    let statusFlag: number;

    if (dataValue.status == false) {
      statusFlag = 0;
    } else {
      statusFlag = 1;
    }
    this.loaderMessage = "Changing program status";
    this.loading = true;
    this.trackerService
      .setProgramStatus(dataValue.id, dataValue.deviceId, statusFlag)
      .subscribe({
        next: (data) => {
          // tslint:disable-next-line:prefer-for-of
          if (statusFlag === 1) {
            this.flagButton = true;
          } else {
            this.flagButton = false;
          }
          this.trackerService
            .getPeripheralsByDeviceId(dataValue.deviceId)
            .subscribe({
              next: (data: any) => {
                this.zoneInfoData = data.data;
                this.loading = false;
              },
              error: (error) => {
                Swal.fire("Failed:", error, "error").then((r) => r);
                this.loading = false;
              },
            });
        },
        error: (error) => {
          // Swal.fire('Failed:', error, 'error').then((r) => r);
          this.loading = false;
        },
      });
  }

  // tslint:disable-next-line:typedef
  redirectToDetails(id: string) {
    this.loaderMessage = "Loading";
    this.loading = true;
    this.router.navigate(["/deviceDetails/" + id], { relativeTo: this.route });
  }

  // tslint:disable-next-line:typedef
  redirectToNormalDetails(id: string) {
    this.loading = true;
    this.loaderMessage = "Loading";
    this.router.navigate(["/normalDeviceDetails/" + id], {
      relativeTo: this.route,
    });
  }
}
