import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./user/login/login.component";
import { RegisterComponent } from "./user/register/register.component";
import { AgmCoreModule } from "@agm/core";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { SharedModule } from "./shared/shared.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { QRCodeModule } from "angularx-qrcode";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgSelectModule } from "@ng-select/ng-select";
import { JwtInterceptor } from "./shared/services/auth.interceptor";
import { TableModule } from "primeng/table";
import { NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { InputSwitchModule } from "primeng/inputswitch";
import { environment } from "../environments/environment";
import { initializeApp } from "firebase/app";
import { ToastModule } from "primeng/toast";
import { ButtonModule } from "primeng/button";
import { NgxLoadingModule, ngxLoadingAnimationTypes } from "ngx-loading";
initializeApp(environment.firebase);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    DashboardComponent,
  ],
  imports: [
    HttpClientModule,
    QRCodeModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    NgSelectModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBAKk0B1D2OdZenf3KWWFvFOW5q-8V9Fpc",
      libraries: ["places", "geometry", "drawing"],
    }),
    TableModule,
    NgbNavModule,
    InputSwitchModule,
    ToastModule,
    ButtonModule,

    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.threeBounce,
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
