<!--
<html>

<body>
  <div class="container">
    <div class="d-flex justify-content-center" style="height: 668px">
      <div class="card">
        <div class="card-header">
          <h3>Forgot password</h3>
          <div class="d-flex justify-content-center links">Forgottan your Password? Enter your email address</div>
        </div>
        <div class="card-body">
          <form [formGroup]="forgotpasswordForm">
            <div class="alert alert-warning" role="alert" *ngIf="submitted && isSuccessful"
              style="padding: 0rem; margin-bottom: 1rem; margin-top: -2rem;">
              {{ message }}
            </div>
            <div class="alert alert-warning" role="alert" *ngIf="submitted && isFailed"
              style="padding: 0rem; margin-bottom: 1rem; margin-top: -2rem;">
              {{ errorMessage }}
            </div>
            <div class="input-group form-group">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-user"></i></span>
              </div>
              <input type="text" class="form-control" formControlName="email"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="enter your email" />
              <div *ngIf="f.email.errors?.pattern" class="form-text text-danger">Email must be a valid email address
              </div>
              <div *ngIf="submitted && f.email.errors" class="form-text text-danger">
                <div *ngIf="f.email.errors.required">Email is required</div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer">
          <div class="d-flex justify-content-center links">
            <div class="form-group">
              <button type="button" class="btn float-right login_btn mr-1" routerLink="/sign-in">Back</button>
            </div>
            <div class="form-group">
              <button type="submit" class="btn float-right login_btn"
                [disabled]="!forgotpasswordForm.valid" (click)="onSubmit()">Send</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>

</html>
-->

<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="utf-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
  <meta name="description" content="" />
  <meta name="author" content="" />
  <title>Password Reset</title>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/js/all.min.js" crossorigin="anonymous"></script>
</head>
<body class="bg-primary">
<div id="layoutAuthentication">
  <div id="layoutAuthentication_content">
    <main>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5">
            <div class="card shadow-lg border-0 rounded-lg mt-5">
              <div class="card-header"><h3 class="text-center font-weight-light my-4">Password Recovery</h3></div>
              <div class="card-body">
                <div class="small mb-3 text-muted">Enter your email address and we will send you a link to reset your password.</div>
                <form>
                  <div class="form-floating mb-3">
                    <label for="inputEmail">Email address</label>
                    <input class="form-control" id="inputEmail" type="email" placeholder="name@example.com" />
                  </div>
                  <div class="d-flex align-items-center justify-content-between mt-4 mb-0">
                    <a class="small" [routerLink]="['/sign-in']">Return to login</a>
                    <a class="btn btn-primary" href="">Reset Password</a>
                  </div>
                </form>
              </div>
              <div class="card-footer text-center py-3">
                <div class="small"><a [routerLink]="['/sign-up']">Need an account? Sign up!</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <div id="layoutAuthentication_footer">
    <footer class="py-4 bg-light mt-auto">
      <div class="container-fluid px-4">
        <div class="d-flex align-items-center justify-content-between small">
          <div class="text-muted">Copyright &copy; Your Website 2021</div>
          <div>
            <a href="#">Privacy Policy</a>
            &middot;
            <a href="#">Terms &amp; Conditions</a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js" crossorigin="anonymous"></script>
</body>
</html>
