import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { first } from "rxjs/operators";
import { User } from "src/app/shared/models/user";
import { AuthService } from "src/app/shared/services/auth.service";
import { MustMatch } from "../../shared/custom-form-validators/must-match.validator";
import Swal from "sweetalert2";
import { AuthenticationService } from "../../authorization/authentication.service";

@Component({
  selector: "app-edit-user",
  templateUrl: "./edit-user.component.html",
  styleUrls: ["./edit-user.component.css"],
})
export class EditUserComponent implements OnInit {
  editUserForm!: FormGroup;
  submitted = false;
  id = this.authenticationService.currentUserValue.mac;
  isAddMode!: boolean;
  loading = false;
  accounts!: any[];
  users!: User[];

  // tslint:disable-next-line:typedef
  get f() {
    return this.editUserForm.controls;
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {}

  mailpattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";

  ngOnInit(): void {
    this.id = this.authenticationService.currentUserValue.mac;
    // this.id = this.route.snapshot.params.id;
    this.isAddMode = !this.id;

    this.editUserForm = this.formBuilder.group(
      {
        // tslint:disable-next-line:max-line-length
        FirstName: [
          this.authenticationService.currentUserValue.FirstName,
          [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(20),
          ],
        ],
        LastName: [
          this.authenticationService.currentUserValue.LastName,
          [Validators.required],
        ],
        EmailId: [
          this.authenticationService.currentUserValue.mac,
          [Validators.required, Validators.pattern(this.mailpattern)],
        ],
        // Password: ['', [this.isAddMode ? Validators.required : Validators.nullValidator, Validators.minLength(6)]],
        // createConfirmPassword: ['', this.isAddMode ? Validators.required : Validators.nullValidator]
      }

      /*{
                validator: MustMatch('createPassword', 'createConfirmPassword')
            }*/
    );
  }

  // tslint:disable-next-line:typedef
  onSubmit() {
    this.submitted = true;
    if (this.editUserForm.invalid) {
      return;
    }
    // display form values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.editUserForm.value, null, 4));

    if (this.isAddMode) {
      this.createUser();
    } else {
      this.updateUser();
    }
  }

  // tslint:disable-next-line:typedef
  private createUser() {
    /*this.authenticationService.create(this.editUserForm.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    Swal.fire('Success', 'User added successfully', 'success');
                    // this.router.navigate(['../'], { relativeTo: this.route });
                },
                error: error => {
                    // this.alertService.error(error);
                    this.loading = false;
                }
            });*/
  }

  // tslint:disable-next-line:typedef
  private updateUser() {
    this.authenticationService
      .updateProfile(this.editUserForm.value)
      .subscribe({
        next: () => {
          Swal.fire("Success", "User updated successfully", "success");
          // this.router.navigate(['../../'], { relativeTo: this.route });
        },
        error: (error) => {
          Swal.fire("Error", error, "error");
          this.loading = false;
        },
      });
  }
}
