import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SharedModule } from "../shared/shared.module";
import { DashboardComponent } from "./dashboard.component";
import { NormalComponent } from "./modes/normal.component";
import { DeviceDetailsComponent } from "./device-details/device-details.component";
import {
  TrackerResolver,
  TrackersResolver,
  wiFiConfigResolver,
  ZoneInfoResolver,
} from "../shared/services/tracker-resolver.service";
import { AuthGuard } from "../authorization/auth.guard";
import { CommonModule } from "@angular/common";
import { DropdownModule } from "primeng/dropdown";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CalendarModule } from "primeng/calendar";
import { ToastModule } from "primeng/toast";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DialogModule } from "primeng/dialog";
import { InputSwitchModule, SliderModule } from "primeng/primeng";
import { NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { NormalDeviceDetailsComponent } from "./modes/normal/normal-device-details.component";
import { MessageService } from "primeng/api";
import { NgxLoadingModule } from "ngx-loading";

const routes: Routes = [
  {
    path: "",
    component: DashboardComponent,
    resolve: {
      deviceData: wiFiConfigResolver,
      deviceInfo: TrackersResolver,
      zoneInfo: ZoneInfoResolver,
    },
    canActivate: [AuthGuard],
  },
  {
    path: "deviceDetails/:id",
    component: DeviceDetailsComponent,
    resolve: {
      getProgramInfo: TrackerResolver,
      zoneInfo: ZoneInfoResolver,
      deviceData: wiFiConfigResolver,
    },
    canActivate: [AuthGuard],
  },

  {
    path: "normal",
    component: NormalComponent,
    resolve: {
      deviceInfo: TrackersResolver,
      zoneInfo: ZoneInfoResolver,
      deviceData: wiFiConfigResolver,
    },
    canActivate: [AuthGuard],
  },
  {
    path: "normalDeviceDetails/:id",
    component: NormalDeviceDetailsComponent,
    resolve: {
      getProgramInfo: TrackerResolver,
      zoneInfo: ZoneInfoResolver,
      deviceData: wiFiConfigResolver,
    },
    canActivate: [AuthGuard],
  },
];
@NgModule({
  declarations: [
    DeviceDetailsComponent,
    NormalComponent,
    NormalDeviceDetailsComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    CommonModule,
    DropdownModule,
    FormsModule,
    CalendarModule,
    ReactiveFormsModule,
    ToastModule,
    ConfirmDialogModule,
    DialogModule,
    SliderModule,
    NgbNavModule,
    InputSwitchModule,
    NgxLoadingModule,
  ],
  providers: [MessageService],
  exports: [RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class DashboardModule {}
