<app-header></app-header>
<div class="main-header">
  <div class="container-fluid px-4">
    <h1 class="mt-4">Profile</h1>
    <ol class="breadcrumb mb-2">
      <li class="breadcrumb-item active">Edit User</li>
    </ol>
  </div>
  <div class="card-body">
    <form [formGroup]="editUserForm" (ngSubmit)="onSubmit()">
      <div class="form-group row">
        <label class="col-form-label col-lg-2 mt-1">First Name</label>
        <div class="col-lg-10">
          <input type="text" class="form-control" formControlName="FirstName"
            [ngClass]="{ 'is-invalid': submitted && f.FirstName.errors }">
          <div *ngIf="submitted && f.FirstName.errors" class="form-text text-danger">First Name is required</div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label col-lg-2 mt-1">Last Name</label>
        <div class="col-lg-10">
          <input type="text" class="form-control" formControlName="LastName"
            [ngClass]="{ 'is-invalid': submitted && f.LastName.errors }">
          <div *ngIf="submitted && f.LastName.errors" class="form-text text-danger">Last Name is required</div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label col-lg-2 mt-1">Email</label>
        <div class="col-lg-10">
          <input type="text" class="form-control" formControlName="EmailId"
            [ngClass]="{ 'is-invalid': submitted && f.EmailId.errors }">
          <div *ngIf="f.EmailId.errors?.pattern" class="form-text text-danger">Email must be a valid email address</div>
          <div *ngIf="submitted && f.EmailId.errors" class="form-text text-danger">
            <div *ngIf="f.EmailId.errors.required">Email is required</div>
          </div>
        </div>
      </div>

<!--      <div class="form-group row">
        <label class="col-form-label col-lg-2 mt-1">Password</label>
        <div class="col-lg-10">
          <input type="password" class="form-control" formControlName="Password"
            [ngClass]="{ 'is-invalid': submitted && f.Password.errors }">
          <div *ngIf="f.Password.errors?.minlength" class="form-text text-danger">Password must be at least 6
            characters</div>
          <div *ngIf="submitted && f.Password.errors" class="form-text text-danger">
            <div *ngIf="f.Password.errors.required">Password is required</div>
          </div>
          <div>{{f.Password.value}}</div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-form-label col-lg-2 mt-1">Confirm Password</label>
        <div class="col-lg-10">
          <input type="password" class="form-control" formControlName="createConfirmPassword"
            [ngClass]="{ 'is-invalid': submitted && f.createConfirmPassword.errors }">
          <div *ngIf="f.createConfirmPassword.errors?.mustMatch" class="form-text text-danger">Passwords must match
          </div>
          <div *ngIf="submitted && f.createConfirmPassword.errors" class="form-text text-danger">
            <div *ngIf="f.createConfirmPassword.errors.required">Confirm Password is required</div>
          </div>
          <div>{{f.createConfirmPassword.value}}</div>
        </div>
      </div>-->


      <!--comment below code For temperory purpose-->
<!--      <div class="form-group row">
        <label class="col-form-label col-lg-2 mt-1">Role</label>
        <div class="col-lg-10">
          <select name="role" id="role" formControlName="role" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.role.errors }">
            <option value="" selected disabled hidden>Select role</option>
            <option value="Admin">Admin</option>
            <option value="User">User</option>
          </select>
          <div *ngIf="submitted && f.role.errors" class="form-text text-danger">Role is required</div>
        </div>
      </div>-->


      <div class="text-center">
        <button class="btn btn-primary mr-1">Update</button>
      </div>
    </form>

  </div>
</div>
