import { Component, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "../../authorization/authentication.service";
import { TrackerService } from "../../shared/services/tracker.service";
import { NgbNavChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { getMessaging, onMessage } from "firebase/messaging";

@Component({
  selector: "app-dashboard",
  templateUrl: "./normal.component.html",
  styleUrls: ["./normal.component.css"],
})
export class NormalComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private trackerService: TrackerService
  ) {}
  public loaderMessage = "";
  public loading = true;
  data: any;
  zoneInfoData: any;
  mac: any;
  deviceId: any;
  intervalAPICallRef: any;
  deviceMode: any;
  active: number | undefined;
  disabled = true;
  val: any;
  // tslint:disable-next-line:typedef
  zone: any;
  deviceStatus: any;
  // tslint:disable-next-line:typedef
  ngOnInit() {
    this.listen();

    // @ts-ignore
    this.mac = this.authenticationService.currentUser.source._value.mac;
    // @ts-ignore
    this.deviceId = this.authenticationService.currentUser.source._value.id;
    // Call when Normal to Maintenance mode
    this.data = this.route.snapshot.data.deviceInfo.data;
    this.zoneInfoData = this.route.snapshot.data.zoneInfo.data;
    this.deviceStatus = this.route.snapshot.data.deviceData.data[0].status;
    this.deviceMode = this.route.snapshot.data.deviceData.data[0].mode;
    if (this.deviceMode === "maintenance") {
      this.router.navigate(["/dashboard"]);
    }
    this.loading = false;
  }

  // tslint:disable-next-line:typedef
  onNavChange(changeEvent: NgbNavChangeEvent) {
    if (changeEvent.nextId === 3) {
      changeEvent.preventDefault();
    }
  }
  // tslint:disable-next-line:typedef
  handleZoneChange(zoneValue: any) {
    let statusFlag: number;

    if (zoneValue.status == false) {
      statusFlag = 0;
    } else {
      statusFlag = 1;
    }

    this.trackerService
      .setZoneStatus(zoneValue.id, zoneValue.deviceId, statusFlag)
      .subscribe({
        next: (data) => {
          // @ts-ignore
          Swal.fire("Success", data.data, "success").then((r) => r);
        },
        error: (error) => {
          Swal.fire("Failed:", error, "error").then((r) => r);
          // this.loading = false;
        },
      });
  }

  // tslint:disable-next-line:typedef
  handleProgramChange(dataValue: any) {
    let statusFlag: number;

    if (dataValue.status == false) {
      statusFlag = 0;
    } else {
      statusFlag = 1;
    }

    this.trackerService
      .setProgramStatus(dataValue.id, dataValue.deviceId, statusFlag)
      .subscribe({
        next: (data) => {
          // @ts-ignore
          Swal.fire("Success", data.data, "success").then((r) => r);
          // this.router.navigate(['/deviceDetails/' + this.mqttData.Device_ID], {relativeTo: this.route});
        },
        error: (error) => {
          Swal.fire("Failed:", error, "error").then((r) => r);
          // this.loading = false;
        },
      });
  }
  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload: any) => {
      if (payload?.data.text === "zoneRefresh") {
        this.trackerService.getPeripheralsByDeviceId(this.deviceId).subscribe({
          next: (data: any) => {
            this.zoneInfoData = data.data;
          },
          error: (error) => {
            Swal.fire("Failed:", error, "error").then((r) => r);
            // this.loading = false;
          },
        });
      }
      if (payload?.data.text === "programRefresh") {
        this.trackerService.getProgramListByDeviceId(this.deviceId).subscribe({
          next: (data: any) => {
            this.data = data.data;
          },
          error: (error) => {
            Swal.fire("Failed:", error, "error").then((r) => r);
            // this.loading = false;
          },
        });
      }
      if (payload?.data.text === "deviceStatusRefresh") {
        location.reload();
      }
    });
  }

  // tslint:disable-next-line:typedef
  redirectToDeviceDetails(id: string) {
    this.loaderMessage = "Loading";
    this.loading = true;
    this.router.navigate(["/normalDeviceDetails/" + id], {
      relativeTo: this.route,
    });
  }
}
