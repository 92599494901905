<!--
<html>

<body>
  <div class="container">
    <div class="d-flex justify-content-center" style="height: 668px">
      <div class="card">
        <div class="card-header">
          <h3>Sign Up</h3>
        </div>
        <div class="card-body">
          <form [formGroup]="signUpForm" (ngSubmit)="onSubmit()">
            <div class="alert alert-success" *ngIf="submitted && isSuccessful"
              style="padding: 0rem; margin-bottom: 1rem; margin-top: -2rem;">
              Your registration is successful!
            </div>
            <div class="alert alert-warning" role="alert" *ngIf="submitted && isSignUpFailed"
              style="padding: 0rem; margin-bottom: 1rem; margin-top: -2rem;">
              Signup failed!<br />{{ errorMessage }}
            </div>

            <div class="input-group form-group">
              <select name="title" id="title" formControlName="title" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.title.errors }">
                <option value="" selected disabled hidden>title</option>
                <option value="Mr">Mr</option>
                <option value="Miss">Miss</option>
                <option value="Mrs">Mrs</option>
                <option value="Mrs">Ms</option>
              </select>
            </div>

            <div class="input-group form-group">
              <input type="text" class="form-control" placeholder="firstName" formControlName="firstName">
            </div>

            <div class="input-group form-group">
              <input type="text" class="form-control" placeholder="lastName" formControlName="lastName">
            </div>

            <div class="input-group form-group">
              <input type="text" class="form-control" placeholder="email" formControlName="email">
            </div>
            <div *ngIf="f.email.errors?.pattern" class="text-danger" style="margin-top: -0.75rem;">Email must be a valid
              email address
            </div>

            <div class="input-group form-group">
              <input type="password" class="form-control" placeholder="password" formControlName="password"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
            </div>
            <div *ngIf="f.password.errors?.minlength" class="text-danger" style="margin-top: -0.75rem;">
              Password must be at least 6 characters</div>
            &lt;!&ndash; <div>{{f.password.value}}</div> &ndash;&gt;

            <div class="input-group form-group">
              <input type="password" class="form-control" placeholder="Repeat Password"
                formControlName="confirmPassword" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }">
            </div>
            <div *ngIf="f.confirmPassword.errors?.mustMatch" class="text-danger" style="margin-top: -0.75rem;">
              Passwords must match
            </div>
            &lt;!&ndash; <div>{{f.confirmPassword.value}}</div> &ndash;&gt;

            &lt;!&ndash; <div class="input-group form-group">
              <ng-select [items]="roles" formControlName="roles" bindLabel="name" bindValue="name"
                placeholder="Select role" [multiple]="true" style="width: 100%;">
              </ng-select> 

            </div>&ndash;&gt;
            &lt;!&ndash; <div *ngIf="f.role.errors?.required" class="text-danger" style="margin-top: -0.75rem;">Role is requeried
            </div> &ndash;&gt;

            <div class="row align-items-center remember">
              <input type="checkbox" formControlName="acceptTerms">Accept Terms
            </div>

            <div class="d-flex justify-content-center links" style="margin-top: 25px;">
              <div class="form-group">
                <button type="button" class="btn float-right login_btn mr-1" routerLink="/sign-in">SignIn</button>
              </div>
              <div class="form-group">
                <button type="submit" class="btn float-right login_btn" [disabled]="!signUpForm.valid">SignUp</button>
              </div>
            </div>

          </form>
        </div>
        <div class="card-footer">
          <div class="links">
            By creating an account you agree to our<a href="#">Terms & privacy</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>

</html>-->
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <meta name="description" content="" />
    <meta name="author" content="" />
    <title>Register</title>
<!--    <link href="css/styles.css" rel="stylesheet" />-->
    <!--<script src="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/js/all.min.js" crossorigin="anonymous"></script>-->
</head>
<body class="bg-primary">
<div id="layoutAuthentication">
    <div id="layoutAuthentication_content">
        <main>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-7">
                        <div class="card shadow-lg border-0 rounded-lg mt-5">
                            <div class="card-header"><h3 class="text-center font-weight-light my-4">Create Account</h3></div>
                            <div class="card-body">
                                <form [formGroup]="signUpForm" (ngSubmit)="onSubmit()">
                                    <div class="row mb-3">
                                        <div class="col-md-6">
                                            <div class="form-floating mb-3 mb-md-0">
                                                <label for="inputFirstName">First name</label>
                                                <input type="text" class="form-control" id="inputFirstName" formControlName="firstName" placeholder="Enter your first name" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-floating">
                                                <label for="inputLastName">Last name</label>
                                                <input type="text" id="inputLastName" class="form-control" placeholder="Enter your last name" formControlName="lastName" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <label for="inputEmail">Email address</label>
                                        <input type="email" id="inputEmail" class="form-control" placeholder="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                    </div>
                                    <div *ngIf="f.email.errors?.pattern" class="text-danger" style="margin-top: -0.75rem;">Email must be a valid
                                        email address
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-md-6">
                                            <div class="form-floating mb-3 mb-md-0">
                                                <label for="inputPassword">Password</label>
                                                <input type="password" id="inputPassword" class="form-control" placeholder="password" formControlName="password"
                                                       [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                            </div>
                                            <div *ngIf="f.password.errors?.minlength" class="text-danger">Password must be at least 6 characters</div>
                                            <!--<div>{{f.password.value}}</div>-->
                                        </div>


                                        <div class="col-md-6">
                                            <div class="form-floating mb-3 mb-md-0">
                                                <label for="inputPasswordConfirm">Confirm Password</label>
                                                <input type="password" id="inputPasswordConfirm" class="form-control" placeholder="Confirm password"
                                                       formControlName="confirmPassword" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }">
                                            </div>
                                            <div *ngIf="f.confirmPassword.errors?.mustMatch" class="text-danger">
                                                Passwords must match
                                            </div>
                                            <!--<div>{{f.confirmPassword.value}}</div>-->
                                        </div>
                                    </div>
                                    <div class="mt-4 mb-0">
                                        <div class="d-grid">
                                            <button class="btn btn-primary btn-block">Create Account</button>
                                        </div>
                                        <!--<button type="submit" class="btn float-right login_btn" [disabled]="!signUpForm.valid">SignUp</button>-->
                                    </div>
                                </form>
                            </div>

                            <div class="card-footer text-center py-3">
                                <div class="small"><a [routerLink]="['/sign-in']">Have an account? Go to login</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
    <div id="layoutAuthentication_footer">
        <footer class="py-4 bg-light mt-auto">
            <div class="container-fluid px-4">
                <div class="d-flex align-items-center justify-content-between small">
                    <div class="text-muted">Copyright &copy; Your Website 2021</div>
                    <div>
                        <a href="#">Privacy Policy</a>
                        &middot;
                        <a href="#">Terms &amp; Conditions</a>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js" crossorigin="anonymous"></script>
<script src="js/scripts.js"></script>
</body>
</html>
