import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../../authorization/authentication.service";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { first } from "rxjs/operators";

declare var $: any;

@Component({
  selector: "app-left-menu",
  templateUrl: "./left-menu.component.html",
  styleUrls: ["./left-menu.component.css"],
})
export class LeftMenuComponent implements OnInit {
  // @ts-ignore
  fbtoken: string;
  public loading = false;
  mode: any;
  modeBolean: false | Boolean | undefined;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.mode = localStorage.getItem("currentMode");
    if (this.mode === "normal") {
      this.modeBolean = true;
    } else {
      this.modeBolean = false;
    }
    $('[data-widget="treeview"]').Treeview("init");
    $('[data-widget="pushmenu"]').PushMenu("toggle");
    // $('body').Layout();
    // $('[data-toggle="push-menu"]').PushMenu();
    // $('[data-widget="treeview"]').Treeview();
    if (screen.width > 1024) {
      $('[data-widget="pushmenu"]').PushMenu("toggle");
    }
    $("body").Layout("fixLayoutHeight");
  }

  // tslint:disable-next-line:typedef
  logout() {
    Swal.fire({
      title: "Are you sure want to logout?",
      text: "You will not be able to use system!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#dc3545",
      confirmButtonColor: "#28a745",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.loading = true;
        this.authenticationService.logout();
        this.loading = false;
        Swal.fire(
          "Logout!",
          "You are successfully logout from the system,",
          "success"
        );
        this.router.navigate(["sign-in"]);
      }
    });
  }

  // tslint:disable-next-line:typedef
  generateFBToken() {
    // @ts-ignore
    this.fbtoken = localStorage.getItem("fbtoken");
    this.loading = true;
    // @ts-ignore
    this.authenticationService
      .sendNotification(this.fbtoken)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data.data.success == true) {
            this.loading = false;
            Swal.fire(
              "Success: ",
              "New Token has been generated successfully",
              "success"
            );
          } else {
            this.loading = false;
            Swal.fire(
              "Error: ",
              "There has some error with notification token,Please try again.",
              "error"
            );
          }
        },
        (err) => {
          this.loading = false;
          Swal.fire("Failed: ", "Do not get new firebase token", "error");
        }
      );
  }
}
