import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiService } from './api.base.service';
import {VehicleType} from '../models/vehicle_type';
import {VehicleList} from '../models/vehicle_list';


@Injectable({
    providedIn: 'root'
})
export class VehicleService {
    private trackersBaseURL = 'trackers';
    private vehicleBaseURL = 'vehicles';
    private deleteVehicleURL = 'vehicles/delete/';

    constructor( private api: ApiService ) {}

    // tslint:disable-next-line:typedef
    getTrackers() {
        return this.api.httpGET<any[]>(this.trackersBaseURL + '/list', 'trackers');
    }

    // tslint:disable-next-line:typedef
    getVehicleType() {
        return [
            new VehicleType(1, 'Two wheeler'),
            new VehicleType(2, 'Three wheeler'),
            new VehicleType(3, 'Four wheeler'),
            new VehicleType(4, 'heavy wheeler'),
        ];
    }

    // tslint:disable-next-line:typedef
    getVehicleList() {
        return [
            new VehicleList(1, 1, 'GJ01MH1111 (TWO)', 51.673858, 7.815982),
            new VehicleList(2, 1, 'GJ01MH1112 (TWO)', 51.373858, 7.215982),
            new VehicleList(3, 2, 'GJ02MH1111 (THREE)', 51.723858, 7.895982),
            new VehicleList(4, 2, 'GJ02MH1112 (THREE)', 51.673858, 7.114575),
            new VehicleList(5, 3, 'GJ02MH1112 (FOUR)', 51.689773, 7.915982),
            new VehicleList(6, 4, 'GJ02MH1112 (HEAVY)', 51.634543, 7.453725),
            //  new VehicleList(5, 2, 'GJ02MH1113 (THREE)'),
            //  new VehicleList(6, 3, 'GJ03MH1111 (FOUR)'),
            //  new VehicleList(7, 3, 'GJ03MH1112 (FOUR)'),
            //  new VehicleList(8, 3, 'GJ03MH1113 (FOUR)'),
            //  new VehicleList(9, 3, 'GJ03MH1114 (FOUR)'),
            //  new VehicleList(10, 4, 'GJ04MH1111 (HEAVY)'),
            //  new VehicleList(11, 4, 'GJ04MH1112 (HEAVY)'),
            //  new VehicleList(12, 4, 'GJ04MH1113 (HEAVY)'),
            //  new VehicleList(13, 4, 'GJ04MH1114 (HEAVY)'),
            //  new VehicleList(14, 4, 'GJ04MH1115 (HEAVY)')
        ];
    }

    // tslint:disable-next-line:typedef
    getVehicleLists(){
        return this.api.httpGET<any[]>(this.vehicleBaseURL + '/list', 'vehicle');
    }

    deleteVehicle(vehicleId: any): Observable<any> {
        return this.api.httpDELETE<any>(`${this.deleteVehicleURL}${vehicleId}`);
    }
}
