<ngx-loading
  [show]="loading"
  [config]="{ backdropBorderRadius: '3px', fullScreenBackdrop: true }"
  [template]="customLoadingTemplate"
></ngx-loading>
<ng-template #customLoadingTemplate>
  <div class="custom-class">
    <h3>{{ loaderMessage }}</h3>
  </div>
</ng-template>

<app-header></app-header>
<div class="main-header">
  <div class="card-body">
    <div id="layoutSidenav_content">
      <div *ngIf="deviceStatus === 0">
        <div
          class="ng-tns-c3-0 ui-toast ui-widget ui-toast-top-right"
          style="margin: 50px -15px 0px 0px; z-index: 1001"
        >
          <p-toastitem
            class="ng-tns-c5-1 ng-trigger ng-trigger-toastAnimation ng-tns-c3-0 ng-star-inserted"
            ng-reflect-message="[object Object]"
            ng-reflect-index="0"
            ng-reflect-show-transition-options="300ms ease-out"
            ng-reflect-hide-transition-options="250ms ease-in"
          >
            <div
              class="ui-toast-message ui-shadow ui-toast-message-info ng-trigger ng-trigger-messageState"
              ng-reflect-klass="ui-toast-message ui-shadow"
              ng-reflect-ng-class="[object Object]"
              style="transform: translateY(0px); opacity: 1"
            >
              <div class="ui-toast-message-content">
                <span
                  class="ui-toast-icon pi ng-tns-c5-1 pi-exclamation-triangle ng-star-inserted"
                  style=""
                ></span>
                <div
                  class="ui-toast-message-text-content ng-tns-c5-1 ng-star-inserted"
                >
                  <div class="ui-toast-summary">Alert</div>
                  <div class="ui-toast-detail">
                    Sprinkler is currently OFF. You can not operate it. Please
                    turn it ON to operate it.
                  </div>
                </div>
              </div>
            </div>
          </p-toastitem>
        </div>
      </div>
      <main>
        <div class="container-fluid px-4">
          <h1 class="mt-4">Dashboard</h1>
          <ol class="breadcrumb mb-4">
            <li class="breadcrumb-item active">Normal</li>
          </ol>
          <div class="mb-3 card">
            <div class="card-header-tab card-header">
              <!--<div class="card-header-title font-size-lg text-capitalize fw-normal">
                                <i class="header-icon lnr-charts icon-gradient bg-happy-green"> </i>
                            </div>-->

              <div class="col-md-12">
                <ul
                  ngbNav
                  #nav="ngbNav"
                  [(activeId)]="active"
                  (navChange)="onNavChange($event)"
                  class="nav-tabs"
                >
                  <li [ngbNavItem]="2">
                    <a ngbNavLink>PROGRAM</a>
                    <ng-template ngbNavContent>
                      <div class="col-sm-12">
                        <div class="row">
                          <div class="col-lg-3" *ngFor="let dataValue of data">
                            <div
                              class="card corner mb-3 widget-chart widget-chart2 text-start card-btm-border card-shadow card-border-tab"
                              [style.background-image]="
                                dataValue.status == '0'
                                  ? ''
                                  : 'linear-gradient( 356deg, #667eea 0%, #764ba2 100% )'
                              "
                            >
                              <div class="widget-chat-wrapper-outer">
                                <div
                                  class="widget-chart-content pt-3 ps-3 pb-1"
                                >
                                  <div class="row1">
                                    <div class="column-switch">
                                      <i
                                        class="fa fa-fw"
                                        aria-hidden="true"
                                        style="font-size: 24px"
                                        title="lightbulb-o"
                                        ></i
                                      >
                                    </div>
                                    <div
                                      class=""
                                      style="float: right;margin: 0 16px 0 0;"
                                    >
                                      <p-inputSwitch
                                        [name]="dataValue.name"
                                        (onChange)="
                                          handleProgramChange(dataValue)
                                        "
                                        [inputId]="dataValue.id"
                                        [(ngModel)]="dataValue.status"
                                        id="{{ dataValue.id }}"
                                        [disabled]="true"
                                      ></p-inputSwitch>
                                    </div>
                                  </div>

                                  <div class="widget-chart-flex">
                                    <div class="widget-numbers">
                                      <div
                                        class="widget-chart-flex"
                                        style="margin: 10px 0 10px 0px"
                                      >
                                        <div class="fsize">
                                          <small class="opacity-5">{{
                                            dataValue.programLabel
                                          }}</small>
                                          <!--<span>Program1</span>-->
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    style="
                                      display: flex;
                                      justify-content: center;
                                    "
                                  >
                                    <button
                                      class="mb-2 me-2 btn btn-alternate button-program"
                                      [ngClass]="{
                                        'btn-alternate-view':
                                          dataValue.status == 1
                                      }"
                                      style="width: 100%; margin: 10px 0 0 -4px"
                                      (click)="
                                        redirectToDeviceDetails(dataValue.id)
                                      "
                                    >
                                      View &nbsp;&nbsp;<i
                                        aria-hidden="true"
                                        title="edit"
                                        class="fa-solid fa-eye"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="1">
                    <a ngbNavLink>ZONES</a>
                    <ng-template ngbNavContent>
                      <div class="col-sm-12">
                        <div class="row">
                          <div
                            class="col-lg-3"
                            *ngFor="let zoneValue of zoneInfoData"
                          >
                            <div
                              class="card corner mb-3 widget-chart widget-chart2 text-start card-btm-border card-shadow card-border-tab"
                              [style.background-image]="
                                zoneValue.status == '0'
                                  ? ''
                                  : 'linear-gradient(356deg, rgb(152 200 230) 0%, rgb(92 115 233) 100%)'
                              "
                            >
                              <div class="widget-chat-wrapper-outer">
                                <div
                                  class="widget-chart-content pt-3 ps-3 pb-1"
                                >
                                  <div class="row1">
                                    <div class="column-switch">
                                      <i
                                        class="fa fa-fw"
                                        aria-hidden="true"
                                        style="font-size: 24px"
                                        title="lightbulb-o"
                                        ></i
                                      >
                                    </div>
                                    <div
                                      class="column-switch"
                                      style="float: right"
                                    >
                                      <p-inputSwitch
                                        [name]="zoneValue.name"
                                        (onChange)="handleZoneChange(zoneValue)"
                                        [inputId]="zoneValue.id"
                                        [(ngModel)]="zoneValue.status"
                                        id="{{ zoneValue.id }}"
                                        [disabled]="true"
                                      ></p-inputSwitch>
                                    </div>
                                  </div>

                                  <div class="widget-chart-flex">
                                    <div class="widget-numbers">
                                      <div
                                        class="widget-chart-flex"
                                        style="margin: 18px 0 10px 0px"
                                      >
                                        <div class="fsize">
                                          <small class="opacity-5">{{
                                            zoneValue.name
                                          }}</small>
                                          <!--<span>Program1</span>-->
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>
<!--

<ng-template #other>
    <main>
        <div class="container-fluid px-4">
            <h1 class="mt-4">Dashboard</h1>
            <ol class="breadcrumb mb-4">
                <li class="breadcrumb-item active">Dashboard</li>
            </ol>
            <div class="row">
                <div class="card-body">
                    <center>
                        <h4>No Device Found</h4>
                    </center>
                </div>
            </div>
        </div>
    </main>
</ng-template>



-->
