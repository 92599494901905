<ngx-loading
  [show]="loading"
  [config]="{ backdropBorderRadius: '3px' }"
></ngx-loading>

<div class="app-sidebar sidebar-shadow">
  <div class="app-header__logo">
    <div class="logo-src">SPRINKLER</div>
    <div class="header__pane ms-auto">
     <!--<i class="fa fa-bars"></i>-->
    </div>
  </div>
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <aside class="main-sidebar app-sidebar sidebar-shadow elevation-4">
        <div class="sidebar">
          <div
            class="user-panel mt-3 pb-3 mb-3 d-flex"
            style="margin-top: 19px !important"
          >
            <div class="image"></div>
          </div>
          <nav class="mt-2">
            <perfect-scrollbar
              ng-reflect-auto-propagation="true"
              class="ps-show-limits"
            >
              <div style="position: static" class="ps">
                <div class="ps-content">
                  <div class="v-sidebar-menu vsm-default">
                    <div class="vsm-list" style="margin: 45px 0 0 0px">
                      <div class="vsm-header">Main Navigation</div>

                      <div *ngIf="modeBolean; else elseBlock">
                        <a
                          routerlink="/normal"
                          routerlinkactive="active-item"
                          class="vsm-link"
                          href="/normal"
                        >
                          <i class="vsm-icon pi pi-th-large"></i>
                          <span class="vsm-title">Dashboard</span></a
                        >
                      </div>
                      <ng-template #elseBlock>
                        <a
                          routerlink="/dashboard"
                          routerlinkactive="active-item"
                          class="vsm-link"
                          href="/dashboard"
                        >
                          <i class="vsm-icon pi pi-th-large"></i>
                          <span class="vsm-title">Dashboard</span></a
                        ></ng-template
                      >
                      <div class="vsm-header">WiFi</div>
                      <div class="vsm-item">
                        <a
                          routerlink="/wifiConfiguration"
                          routerlinkactive="active-item"
                          class="vsm-link"
                          href="/wifiConfiguration"
                        >
                          <i class="vsm-icon pi pi-wifi"></i>
                          <span class="vsm-title">WiFi Configuration</span></a
                        >
                      </div>

                      <div class="vsm-header">Notifications</div>
                      <div class="vsm-item" (click)="generateFBToken()">
                        <a
                          routerlink="javascript:void(0);"
                          class="vsm-link"
                          href="javascript:void(0);"
                        >
                          <i class="vsm-icon pi pi-bell"></i
                          ><span class="vsm-title">Notification</span></a
                        >
                      </div>

                      <div class="vsm-header">LogOut</div>
                      <div class="vsm-item" (click)="logout()">
                        <a
                          routerlink="javascript:void(0);"
                          class="vsm-link"
                          href="javascript:void(0);"
                        >
                          <i class="vsm-icon pi pi-sign-out"></i
                          ><span class="vsm-title">Logout</span></a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ps__rail-x" style="left: 0px; bottom: 0px">
                  <div
                    class="ps__thumb-x"
                    tabindex="0"
                    style="left: 0px; width: 0px"
                  ></div>
                </div>
                <div class="ps__rail-y" style="top: 0px; right: 0px">
                  <div
                    class="ps__thumb-y"
                    tabindex="0"
                    style="top: 0px; height: 0px"
                  ></div>
                </div>
              </div>
            </perfect-scrollbar>
          </nav>
        </div>
      </aside>
    </div>
  </div>
</div>
