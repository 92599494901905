import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { first } from "rxjs/operators";
import { User } from "../models/user";
import { AuthService } from "../services/auth.service";
import { AuthenticationService } from "../../authorization/authentication.service";
import { TrackerService } from "../services/tracker.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  headerData: any;
  errorMessage: any;
  programList: any;
  public loading = true;
  public loaderMessage = "";
  constructor(
    private router: Router,
    private authService: AuthService,
    private authenticationService: AuthenticationService,
    private trackerService: TrackerService
  ) {}

  currentUser: any;
  roles: any;
  email: any;
  users!: User[];
  jwtToken: any;
  account: any;

  sidebarHover = false;
  toggleSidebar = false;
  // toggleSidebarMobile = false;
  // toggleHeaderMobile = false;
  toggleFixedFooter = false;
  // tslint:disable-next-line:variable-name
  mac_add: string | undefined;

  // tslint:disable-next-line:typedef
  statusValue: any;
  status: boolean | undefined;
  modeValue: any;
  mode: boolean | undefined;

  // tslint:disable-next-line:typedef
  toggleSidebarMobile() {
    // this.toggleSidebarMobile = !this.toggleSidebarMobile;
  }

  // tslint:disable-next-line:typedef
  toggleHeaderMobile() {
    // this.toggleHeaderMobile = !this.toggleHeaderMobile;
  }

  // tslint:disable-next-line:typedef
  ngOnInit() {
    this.account = this.authenticationService.currentUserValue;

    // @ts-ignore
    // const deviceId = this.authenticationService.currentUser.source._value.id;
    this.mac_add = this.account.mac
      .replace(/(.{2})/g, "$1:")
      .slice(0, -1)
      .toUpperCase();

    this.authenticationService
      .getDeviceInfoByDeviceId(this.account.id)
      .pipe(first())
      .subscribe(
        (data) => {
          this.headerData = data.data[0];
          this.modeValue = this.headerData.mode;
          this.statusValue = this.headerData.status;
          if (this.modeValue == "normal") {
            localStorage.setItem("currentMode", this.modeValue);
            this.mode = false;
          }
          if (this.modeValue == "maintenance") {
            localStorage.setItem("currentMode", this.modeValue);
            this.mode = true;
          }
          if (this.statusValue == 0) {
            this.status = false;
          }
          if (this.statusValue == 1) {
            this.status = true;
          }
        },
        (err) => {
          // display api error message.
          this.errorMessage = err.error.data;
        }
      );
    this.loading = false;
  }

  // tslint:disable-next-line:typedef
  modeChange($event: any) {
    const isChecked = $event.checked;
    this.loading = true;
    this.loaderMessage = "Updating device mode";
    // this.mode = isChecked;
    // @ts-ignore
    let modeType: string;
    const deviceid = this.account.id;
    if (isChecked == true) {
      localStorage.setItem("currentMode", "maintenance");
      modeType = "maintenance";
      const modeValue = modeType;
      // fetch all program list.
      this.trackerService
        .getProgramListByDeviceId(this.account.id)
        .pipe(first())
        .subscribe(
          (data) => {
            // @ts-ignore
            this.programList = data.data;

            // Call when Normal to Maintenance mode
            for (let i = 0; i < this.programList.length; i++) {
              if (this.programList[i].status == 1) {
                const id = this.programList[i].id;
                const idDevice = this.account.id;
                const status = 0;

                // set all the program status 0 if program status = 1.
                this.trackerService
                  .setProgramStatus(id, idDevice, status)
                  .subscribe({
                    next: (dataStatus: any) => {},
                  });
              }
            }
            // Set multizone.

            this.trackerService.setMultiZone(deviceid).subscribe({
              next: (dataStatus: any) => {},
            });
          },
          (err) => {
            this.loading = false;
            // display api error message.
            this.errorMessage = err.error.data;
          }
        );
      this.trackerService.changeMode(deviceid, modeValue).subscribe({
        next: () => {
          setTimeout(() => {
            this.router.navigate(["/dashboard"]);
          }, 5000);
        },
      });
    } else {
      localStorage.setItem("currentMode", "normal");
      modeType = "normal";
      const modeValue = modeType;
      // fetch all program list.
      this.trackerService
        .getProgramListByDeviceId(this.account.id)
        .pipe(first())
        .subscribe(
          (data) => {
            // @ts-ignore
            this.programList = data.data;

            // Call when Normal to Maintenance mode
            for (let i = 0; i < this.programList.length; i++) {
              if (this.programList[i].status == 1) {
                const id = this.programList[i].id;
                const idDevice = this.account.id;
                const status = 0;

                // set all the program status 0 if program status = 1.
                this.trackerService
                  .setProgramStatus(id, idDevice, status)
                  .subscribe({
                    next: (dataStatus: any) => {},
                  });
              }
            }
            // Set multizone.

            this.trackerService.setMultiZone(deviceid).subscribe({
              next: (dataStatus: any) => {
                // Change Mode.
                this.trackerService.changeMode(deviceid, modeValue).subscribe({
                  next: () => {
                    setTimeout(() => {
                      this.router.navigate(["/normal"]);
                    }, 5000);
                  },
                });
              },
            });
          },
          (err) => {
            this.loading = false;
            // display api error message.
            this.errorMessage = err.error.data;
          }
        );
    }
  }
}
