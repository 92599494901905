import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';

import {AuthService} from '../../shared/services/auth.service';
import {MustMatch} from '../../shared/custom-form-validators/must-match.validator';
import Swal from "sweetalert2";

enum TokenStatus {
    Validating,
    Valid,
    Invalid
}

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
    TokenStatus = TokenStatus;
    tokenStatus = TokenStatus.Validating;
    token = null;
    token1: any;
    resetPasswordForm!: FormGroup;
    loading = false;
    submitted = false;

    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                private authService: AuthService) {
    }

    ngOnInit(): void {
        this.resetPasswordForm = this.formBuilder.group({
            token: [''],
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', Validators.required],
        }, {
            validator: MustMatch('password', 'confirmPassword')
        });

        this.token1 = this.route.snapshot.paramMap.get('token');
        // remove token from url to prevent http referer leakage
        this.router.navigate([], {relativeTo: this.route, replaceUrl: true});

        this.authService.validateResetToken(this.token1)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.token = this.token1;
                    this.tokenStatus = TokenStatus.Valid;
                },
                error: () => {
                    this.tokenStatus = TokenStatus.Invalid;
                }
            });
    }

    // tslint:disable-next-line:typedef
    get f() {
        return this.resetPasswordForm.controls;
    }

    // tslint:disable-next-line:typedef
    onSubmit() {
        this.submitted = true;
        if (this.resetPasswordForm.invalid) {
            return;
        }

        this.loading = true;
        // @ts-ignore
        this.authService.resetPassword(this.token, this.f.password.value, this.f.confirmPassword.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    Swal.fire('Success', 'Your Password reset successfully, Now you can login', 'success');
                    this.router.navigate(['/sign-in'], {relativeTo: this.route});
                },
                error: () => {
                    // Swal.fire('Error: ', error, 'error');
                    this.loading = false;
                }
            });
    }
}
