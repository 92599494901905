import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

import { ApiService } from "./api.base.service";
import { environment } from "../../../environments/environment";
// import {User} from "../models/user";

@Injectable({
  providedIn: "root",
})
export class TrackerService {
  private BaseURL = "dashboard/";
  private BaseProgramURL = "program/";
  private userBaseURL = "users/";
  private deleteTrackerURL = "users/userDeviceRemove";
  private addBaseURL = "addDevices";
  private getWiFiConfigBaseURL = "getWiFiConfig";
  private getProductGroupFileURL = "addProductGroupFile";

  constructor(private api: ApiService) {}

  // tslint:disable-next-line:typedef
  getDeviceInfoByDeviceId(param: any) {
    return this.api.httpPOST<any[]>(this.BaseURL + "getDeviceInfoByDeviceId", {
      deviceId: param,
    });
  }

  // tslint:disable-next-line:typedef
  getProgramListByDeviceId(param: any) {
    return this.api.httpPOST<any[]>(
      this.BaseProgramURL + "getProgramListByDeviceId",
      { deviceId: param }
    );
  }

  // tslint:disable-next-line:typedef
  getPeripheralsByDeviceId(deviceid: any) {
    return this.api.httpPOST<any[]>(this.BaseURL + "getPeripheralsByDeviceId", {
      deviceId: deviceid,
    });
  }

  // tslint:disable-next-line:typedef
  setProgramStatus(Id: any, deviceid: any, statusVal: any) {
    return this.api.httpPOST<any[]>(this.BaseProgramURL + "setProgramStatus", {
      id: Id,
      deviceId: deviceid,
      status: statusVal,
    });
  }

  // tslint:disable-next-line:typedef
  setZoneStatus(Id: any, deviceid: any, statusVal: any) {
    return this.api.httpPOST<any[]>(this.BaseURL + "setZone", {
      id: Id,
      deviceId: deviceid,
      status: statusVal,
    });
  }

  // tslint:disable-next-line:typedef
  getProgram(reqBody: string | null) {
    return this.api.httpPOST<any[]>(this.BaseProgramURL + "getProgram", {
      id: reqBody,
    });
  }

  // tslint:disable-next-line:max-line-length typedef
  updateProgram(newDvc: {
    peripherals: any;
    days: any;
    startTime: string;
    id: any;
    isRepeat: any;
    deviceId: any;
    enabled: any;
    status: number;
    programLabel: any;
  }) {
    // tslint:disable-next-line:max-line-length
    return this.api.httpPOST<any[]>(this.BaseProgramURL + "updateProgram", {
      peripherals: newDvc.peripherals,
      days: newDvc.days,
      startTime: newDvc.startTime,
      id: newDvc.id,
      isRepeat: newDvc.isRepeat,
      deviceId: newDvc.deviceId,
      enabled: newDvc.enabled,
      status: newDvc.status,
      programLabel: newDvc.programLabel,
    });
  }

  setMultiZone(deviceid: any) {
    return this.api.httpPOST<any[]>(this.BaseURL + "setMultiZone", {
      deviceId: deviceid,
      zones: [
        { zone1: 0 },
        { zone2: 0 },
        { zone3: 0 },
        { zone4: 0 },
        { zone5: 0 },
        { zone6: 0 },
        { zone7: 0 },
        { zone8: 0 },
        { zone9: 0 },
        { zone10: 0 },
      ],
    });
  }

  // tslint:disable-next-line:typedef
  changeMode(deviceid: string, modeValue: string) {
    return this.api.httpPOST<any[]>(this.BaseURL + "changeMode", {
      deviceId: deviceid,
      mode: modeValue,
    });
  }
  // tslint:disable-next-line:typedef
  updateWifiDetails(params: any) {
    return this.api.httpPOST<any>(this.BaseURL + "updateWifiDetails", params);
  }

  // tslint:disable-next-line:typedef
  updateDevice(params: any) {
    return this.api.httpPOST<any>(this.BaseURL + "updateDevices", params);
  }

  // tslint:disable-next-line:typedef
  /*getTracker(trackerId: number) {
        return this.api.httpGET<any>(this.trackersBaseURL + '/' + trackerId, 'tracker');
    }*/

  deleteDevice(emailId: any, deviceId: any): Observable<any> {
    return this.api.httpPOST<any>(this.deleteTrackerURL, {
      EmailId: emailId,
      dvcId: deviceId,
    });
  }

  // tslint:disable-next-line:typedef
  addProductGroupFile(params: any) {
    return this.api.httpPOST<any>(
      this.BaseURL + this.getProductGroupFileURL,
      params
    );
  }
}
