import { MapsAPILoader, MouseEvent } from "@agm/core";
import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Location } from "@angular/common";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControlName,
} from "@angular/forms";
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from "@angular/router";
import { TrackerService } from "src/app/shared/services/tracker.service";
import { AuthService } from "../../../shared/services/auth.service";
import { AuthenticationService } from "../../../authorization/authentication.service";
import { ConfirmationService, MessageService } from "primeng/api";
interface addedZone {
  [key: string]: string;
}

@Component({
  selector: "app-add-trackers",
  templateUrl: "./normal-device-details.component.html",
  styleUrls: ["./normal-device-details.component.css"],
  providers: [ConfirmationService, MessageService],
})
export class NormalDeviceDetailsComponent implements OnInit {
  addedZones: any;
  details: any;
  email: any;
  intervalAPICallRef: any;
  devieId: any;
  public loading = true;
  public loaderMessage = "";
  oldSelectedZones: string[] | undefined;
  oldProgramZones: string[] | undefined;
  newProgramZones: string[] | undefined;
  oldDeviceZones: string[] | undefined;
  removedZoneNames: string[] | undefined;
  data: any;
  editProgram!: FormGroup | undefined;
  startTime: any;

  enabled: any;
  isRepeat: any;
  programLabel: any;
  // tslint:disable-next-line:variable-name
  days_sun: any;
  // tslint:disable-next-line:variable-name
  days_mon: any;
  // tslint:disable-next-line:variable-name
  days_tue: any;
  // tslint:disable-next-line:variable-name
  days_wed: any;
  // tslint:disable-next-line:variable-name
  days_thu: any;
  // tslint:disable-next-line:variable-name
  days_fri: any;
  // tslint:disable-next-line:variable-name
  days_sat: any;

  editDurationDisplay: boolean = false;

  editProgramName: any;
  id: any;
  deviceId: any;
  display: boolean = false;
  durationVal: any;
  deviceStatus: any;
  deviceMode: any;
  editDurationZoneName:
    | ((label?: string) => void)
    | string
    | number
    | HTMLTimeElement
    | undefined;
  constructor(
    private _location: Location,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private serviceAuthentication: AuthenticationService,
    // tslint:disable-next-line:max-line-length
    private trackerService: TrackerService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {}
  // tslint:disable-next-line:typedef
  ngOnInit() {
    this.data = this.route.snapshot.data.getProgramInfo.data[0];
    this.deviceStatus = this.route.snapshot.data.deviceData.data[0].status;
    this.deviceMode = this.route.snapshot.data.deviceData.data[0].mode;

    if (
      this.deviceMode === "maintenance" &&
      this.data.status === 1 &&
      this.data.status == 1
    ) {
      this.router.navigate(["/normalDeviceDetails/" + this.data.id]);
    }

    this.oldProgramZones = this.data.peripherals;
    const programZones = this.data.peripherals;
    this.oldDeviceZones = this.route.snapshot.data.zoneInfo.data;
    const deviceZones = this.route.snapshot.data.zoneInfo.data;

    this.id = this.data.id;
    this.deviceId = this.data.deviceId;

    const zoneArray: string[] = [];
    const addedzoneArray: any = [];
    const selectedZoneArray: string[] = [];

    if (programZones.length > 0) {
      programZones.map((items: any) => {
        for (const [key, value] of Object.entries(items)) {
          zoneArray.push(key);
          addedzoneArray.push({ name: key, time: value });
        }
      });
      this.addedZones = addedzoneArray;
      deviceZones.map((items: any) => {
        if (!zoneArray.includes(items.name)) {
          selectedZoneArray.push(items);
        }
        this.addedZones.map((zones: any) => {
          if (zones.name === items.name) {
            zones.status = items.status;
          }
        });
      });
      this.oldSelectedZones = selectedZoneArray;
    } else {
      this.oldSelectedZones = deviceZones;
    }
    this.editProgram = this.formBuilder.group({
      enabled: [this.data.enabled],
      isRepeat: [this.data.isRepeat],
      programLabel: [this.data.programLabel],
      days_sun: [this.data.days[0]],
      days_mon: [this.data.days[1]],
      days_tue: [this.data.days[2]],
      days_wed: [this.data.days[3]],
      days_thu: [this.data.days[4]],
      days_fri: [this.data.days[5]],
      days_sat: [this.data.days[6]],
      startTime: this.data.startTime.slice(0, -3),
      zones: "",
    });
    // this.editProgram.disable();
    this.startTime = this.data.startTime.slice(0, -3);
    this.loading = false;
  }
  // tslint:disable-next-line:typedef
  getAddedZoneData() {}
  // tslint:disable-next-line:typedef
  onSubmit() {
    let timeValue;
    // @ts-ignore
    if (typeof this.editProgram?.value.startTime === "object") {
      timeValue = this.convertInTime(this.editProgram.value.startTime);
    } else {
      timeValue = this.editProgram?.value.startTime;
    }
    // @ts-ignore
    const newDvc = {
      id: this.id,
      deviceId: this.deviceId,
      // @ts-ignore
      startTime: timeValue,
      days: [
        // @ts-ignore
        this.editProgram.value.days_sun ? 1 : 0,
        // @ts-ignore
        this.editProgram.value.days_mon ? 1 : 0,
        // @ts-ignore
        this.editProgram.value.days_tue ? 1 : 0,
        // @ts-ignore
        this.editProgram.value.days_wed ? 1 : 0,
        // @ts-ignore
        this.editProgram.value.days_thu ? 1 : 0,
        // @ts-ignore
        this.editProgram.value.days_fri ? 1 : 0,
        // @ts-ignore
        this.editProgram.value.days_sat ? 1 : 0,
      ],
      peripherals: this.oldProgramZones,
      // @ts-ignore
      isRepeat: this.editProgram.value.isRepeat ? 1 : 0,
      // @ts-ignore
      enabled: this.editProgram.value.enabled ? 1 : 0,
      status: 1,
      // @ts-ignore
      programLabel: this.editProgram.value.programLabel,
    };

    this.trackerService.updateProgram(newDvc).subscribe({
      next: () => {
        // this.alertService.success('Tracker inserted successfully.', {keepAfterRouteChange: true});
        this.router.navigate(["/dashboard"], { relativeTo: this.route });
      },
    });
    // }
  }

  // tslint:disable-next-line:typedef
  convertInTime(dateTime: string) {
    // @ts-ignore
    const hours = dateTime.getHours();
    // @ts-ignore
    const minutes = dateTime.getMinutes();
    // tslint:disable-next-line:label-position
    return `${hours}:${this.padTo2Digits(minutes)}`;
  }

  // tslint:disable-next-line:typedef
  padTo2Digits(num: number) {
    return num.toString().padStart(2, "0");
  }

  // tslint:disable-next-line:typedef
  showDialog() {
    this.display = true;
  }

  // tslint:disable-next-line:typedef
  onBack() {
    this.loading = true;
    this.loaderMessage = "Loading";
    if (this.deviceMode === "normal") {
      this.router.navigate(["/normal"]);
    } else {
      this._location.back();
    }
  }
}
