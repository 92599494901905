import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { finalize, first } from "rxjs/operators";
import { AuthService } from "src/app/shared/services/auth.service";
import { MustMatch } from "../../shared/custom-form-validators/must-match.validator";
import Swal from "sweetalert2";

@Component({
  selector: "app-verify-email",
  templateUrl: "./verify-email.component.html",
  styleUrls: ["./verify-email.component.css"],
})
export class VerifyEmailComponent implements OnInit {
  token = null;
  token1: any;
  resetPasswordForm!: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.token1 = this.route.snapshot.paramMap.get("EmailId");
    // remove token from url to prevent http referer leakage
    this.router.navigate([], { relativeTo: this.route, replaceUrl: true });

    this.authService
      .verifyEmail(this.token1)
      .pipe(first())
      .subscribe({
        next: () => {
          this.token = this.token1;
          Swal.fire(
            "Success",
            "Your Account verification is successful, Now you can Sign in.",
            "success"
          );
          this.router.navigate(["/sign-in"], { relativeTo: this.route });
        },
        error: () => {
          Swal.fire(
            "Failed:",
            "Token validation failed or Token is not valid.",
            "error"
          );
          this.router.navigate(["/sign-in"], { relativeTo: this.route });
        },
      });
  }
}
